// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import type { Size } from 'react-virtualized';
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer';
import { withTheme } from '../app/context/theme-context';
import type { TableSize } from '../model';
import type { CompiledTheme } from '../model/themes';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isAutoHeight: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ isAutoHeight }) => (isAutoHeight ? 'auto' : '100%'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flex: ({ isAutoHeight }) => (isAutoHeight ? undefined : '1 0 auto'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullHeightAutoSizer = styled(AutoSizer)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	height: '100% !important',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AutoSizedContentWrapper = styled.div<{
	width: string | number;
	height: string | number;
}>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
});

type Props = {
	width: number;
	height: number;
	isAutoHeight: boolean;
	setTableSize: (size: TableSize) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	TableMain: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	DetailsPanel: ComponentType<Record<any, any>>;
	theme: CompiledTheme;
};

// eslint-disable-next-line jira/react/no-class-components
class Table extends PureComponent<Props> {
	onResize = (size: Size) => {
		const { setTableSize, theme } = this.props;

		// AutoSizer uses offsetWidth/offsetHeight for dimensions which rounds and can cause scrollbars.
		// i.e. height of 700.5 will be rounded to 701
		// We need to remove a single pixel to prevent this
		// https://github.com/bvaughn/react-virtualized/issues/1287
		const width = size.width - 1;
		const height = size.height - 1;
		setTableSize({
			height,
			width,
			widthWithoutScrollbarOffset: width - theme.scrollbar.offset,
			rowMargin: theme.header.sideMargin * 2,
		});
	};

	render() {
		const { width, height, isAutoHeight, TableMain, DetailsPanel } = this.props;

		const autoSizerStyle = isAutoHeight ? { height: 'auto' } : {};

		return (
			<Wrapper
				isAutoHeight={isAutoHeight}
				data-component-selector="virtual-table.view.table-wrapper"
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
				<FullHeightAutoSizer onResize={this.onResize} style={{ ...autoSizerStyle }}>
					{() => (
						<AutoSizedContentWrapper width={width} height={height}>
							<TableMain />
							<DetailsPanel />
						</AutoSizedContentWrapper>
					)}
				</FullHeightAutoSizer>
			</Wrapper>
		);
	}
}

export default withTheme(Table);
