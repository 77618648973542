import flow from 'lodash/flow';
import ReduxAnalyticsData from '@atlassian/jira-analytics-web-react/src/components/redux-analytics-data.tsx';
import { connect } from '@atlassian/jira-react-redux';
import type { State } from '../../../../../state/reducers/types';
import { isQueueFavorited } from '../../../../../state/selectors/favoriting';
import { getQueueId } from '../../../../../state/selectors/queue';
import FavoriteButton from './view';

const mapStateToProps = (state: State) => ({
	isFavorite: isQueueFavorited(state),
	queueId: getQueueId(state),
});

export default flow(
	ReduxAnalyticsData((state) => ({
		attributes: {
			queueId: getQueueId(state),
			isFavorite: !isQueueFavorited(state),
		},
	})),
	connect(mapStateToProps, {}),
)(
	// @ts-expect-error - Argument of type '({ isFavorite, queueId }: Props) => JSX.Element' is not assignable to parameter of type 'ComponentType<unknown>'.
	FavoriteButton,
);
