import { createSelector } from 'reselect';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales';
import type { InitialState as State } from '@atlassian/jira-servicedesk-common/src/model/types';
import {
	type BaseUrl,
	type CloudId,
	toCloudId,
} from '@atlassian/jira-shared-types/src/general.tsx';

export const getAppProps = (state: State) => state.appProps;

/** @deprecated baseUrl is always an empty string / unused. Remove code that checks or uses it. */
export const getBaseUrl = (_: State) => '';
export const getCloudId = (state: State) => getAppProps(state).cloudId;
export const getFabricPfDirUrl = (state: State) => getAppProps(state).fabricPfDirUrl;
export const getAtlassianAccountId = (state: State) => getAppProps(state).atlassianAccountId;
export const getProjectKey = (state: State) => getAppProps(state).projectKey;
export const getProjectName = (state: State) => getAppProps(state).projectName;
export const getLocale = (state: State): Locale => getAppProps(state).locale;
// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'.
export const getIsAdmin = (state: State): boolean => getAppProps(state).isAdmin;
// @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'number'.
export const getProjectId = (state: State): number => getAppProps(state).projectId;

export type IssueTableAppProps = {
	/** @deprecated baseUrl is always an empty string / unused. Remove code that checks or uses it. */
	baseUrl?: BaseUrl;
	cloudId?: CloudId;
	fabricPfDirUrl?: string;
	atlassianAccountId?: string;
};

export const getAppPropsForIssueTable = createSelector(
	getBaseUrl,
	getCloudId,
	getFabricPfDirUrl,
	getAtlassianAccountId,
	(baseUrl, cloudId, fabricPfDirUrl, atlassianAccountId) => ({
		baseUrl: '',
		// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
		cloudId: toCloudId(cloudId),
		fabricPfDirUrl,
		atlassianAccountId,
	}),
);
