import { assocIn } from 'icepick';
import throttle from 'lodash/throttle';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	CONFLICT,
	FORBIDDEN,
	NOT_FOUND,
} from '@atlassian/jira-common-constants/src/http-status-codes';
import { getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { DEFAULT_QUEUE_ID } from '@atlassian/jira-servicedesk-queues-common/src/constants';
import type { Page } from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types';
import { setQueues } from '@atlassian/jira-servicedesk-queues-nav-api/src/services/set-queues/index.tsx';
import { updateQueuesVisibility } from '@atlassian/jira-servicedesk-queues-nav-api/src/services/update-queues-visibility/index.tsx';
import type { QueueVisibilityPayload } from '@atlassian/jira-servicedesk-queues-nav-api/src/types.tsx';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import {
	getQueuesForCategory,
	queuesToNavItems,
	setQueuesForCategory,
} from '../../../common/utils';
import { hasRequestedCountSelector, isCountLoadingSelector } from '../../../selectors';
import type { ChildrenProps as Actions } from '../types';
import {
	enableRefreshForQueues,
	refreshIssueCountForQueues,
	refreshIssueCountForVisibleAndStarredQueues,
} from './refresh-issue-count';
import type { ErrorAnalyticsPayload, ErrorCallback, SuccessCallback, Updater } from './types';
import {
	addItem,
	deleteItem,
	editItem,
	editItems,
	enableSelectedQueuesVisibility,
	moveItem,
	moveItemAbove,
	moveItemBelow,
	moveToTop,
} from './utils';

const throttleNavItemsRefreshRate = (): string =>
	getMultivariateFeatureFlag('jsm.dynamic.queues.issue.count.refresh.interval', '60000', [
		'60000',
		'120000',
		'300000',
		'90000',
		'86400000', // 24hrs - for experimenting with hello
	]);

const getThrottledRefresh = () => Number(throttleNavItemsRefreshRate());

const getAnalyticsPayload = (err: FetchError): ErrorAnalyticsPayload => {
	const wasQueueDeleted = err?.statusCode === NOT_FOUND;
	const queueConflictOccurred = err?.statusCode === CONFLICT;
	const queueActionNotPermitted = err?.statusCode === FORBIDDEN;

	return {
		wasQueueDeleted,
		queueConflictOccurred,
		queueActionNotPermitted,
		isClientFetchError: isClientFetchError(err),
	};
};

export const getActions = (
	category: ItsmPractice,
	update: Updater,
	restParams: {
		baseUrl: string;
		projectKey: string;
	},
): Actions => {
	const actions = {
		addItem: (item: Page) => {
			update((data) => {
				const queues = getQueuesForCategory(data, category);

				if (queues) {
					return setQueuesForCategory(data, category, addItem(queues, item));
				}

				throw new Error(`Cannot add item because ${category} does not exist in resource`);
			});
		},
		deleteItem: (id: string) => {
			update((data) => {
				const queues = getQueuesForCategory(data, category);

				if (queues) {
					return setQueuesForCategory(data, category, deleteItem(queues, id));
				}

				throw new Error(`Cannot delete item because ${category} does not exist in resource`);
			});
		},
		editItem: (item: Page) => {
			update((data) => {
				const queues = getQueuesForCategory(data, category);

				if (queues) {
					return setQueuesForCategory(data, category, editItem(queues, item));
				}

				throw new Error(`Cannot edit item because ${category} does not exist in resource`);
			});
		},
		moveItem: (from: number, to: number) => {
			if (from !== to) {
				update((data) => {
					const queues = getQueuesForCategory(data, category);

					if (queues) {
						const { baseUrl, projectKey } = restParams;
						const result = moveItem(queues, from, to);

						setQueues(baseUrl, projectKey, queuesToNavItems(result), category);
						return setQueuesForCategory(data, category, result);
					}

					throw new Error(`Cannot move item because ${category} does not exist in resource`);
				});
			}
		},
		moveItemBelow: (
			queueId: number,
			targetQueueId: number,
			onSuccessCallback: SuccessCallback,
			onErrorCallback: ErrorCallback,
		) => {
			update((data) => {
				const queues = getQueuesForCategory(data, category);

				if (queues) {
					const { baseUrl, projectKey } = restParams;
					const result = moveItemBelow(queues, queueId, targetQueueId);
					setQueues(baseUrl, projectKey, queuesToNavItems(result), category)
						.then(() => onSuccessCallback())
						.catch((err: FetchError) => onErrorCallback(getAnalyticsPayload(err)));
					return setQueuesForCategory(data, category, result);
				}

				throw new Error(`Cannot move item below because ${category} does not exist in resource`);
			});
		},
		moveItemAbove: (
			queueId: number,
			targetQueueId: number,
			onSuccessCallback: SuccessCallback,
			onErrorCallback: ErrorCallback,
		) => {
			update((data) => {
				const queues = getQueuesForCategory(data, category);
				if (queues) {
					const { baseUrl, projectKey } = restParams;
					const result = moveItemAbove(queues, queueId, targetQueueId);
					setQueues(baseUrl, projectKey, queuesToNavItems(result), category)
						.then(() => onSuccessCallback())
						.catch((err: FetchError) => onErrorCallback(getAnalyticsPayload(err)));
					return setQueuesForCategory(data, category, result);
				}

				throw new Error(`Cannot move item above because ${category} does not exist in resource`);
			});
		},
		moveToTop: (
			queueId: number,
			onSuccessCallback: SuccessCallback,
			onErrorCallback: ErrorCallback,
		) => {
			update((data) => {
				const queues = getQueuesForCategory(data, category);
				if (queues) {
					const { baseUrl, projectKey } = restParams;
					const result = moveToTop(queues, queueId);
					setQueues(baseUrl, projectKey, queuesToNavItems(result), category)
						.then(() => onSuccessCallback())
						.catch((err: FetchError) => onErrorCallback(getAnalyticsPayload(err)));
					return setQueuesForCategory(data, category, result);
				}

				throw new Error(`Cannot move item to top because ${category} does not exist in resource`);
			});
		},
		refreshNavItems: throttle((analyticsEvent?: UIAnalyticsEvent) => {
			setTimeout(
				() => {
					update((data) => {
						const isCountLoading = isCountLoadingSelector(data, category);
						if (isCountLoading) {
							return data;
						}
						if (restParams.projectKey) {
							refreshIssueCountForVisibleAndStarredQueues(
								update,
								{ ...restParams, category },
								analyticsEvent,
							);
						}
						const updatedData = data ? assocIn(data, [category, 'isCountLoading'], true) : null;
						return updatedData;
					});
					// Jitter the call randomly between 1 - 5secs in case of multiple tab requests
				},
				Math.floor(Math.random() * 5000) + 1000,
			);
		}, getThrottledRefresh()),
		initialFetchNavItems: (analyticsEvent?: UIAnalyticsEvent) => {
			update((data) => {
				const isCountLoading = isCountLoadingSelector(data, category);
				const hasRequestedCount = hasRequestedCountSelector(data, category);
				if (!isCountLoading && !hasRequestedCount) {
					actions.refreshNavItems(analyticsEvent);
				}
				return data;
			});
		},
		refreshIssueCountForItems: (queueIds: string[], analyticsEvent?: UIAnalyticsEvent) => {
			refreshIssueCountForQueues(update, { ...restParams, category }, queueIds, analyticsEvent);
		},
		editItemIssueCount: (id: string, count: number) => {
			update((data) => {
				const queues = getQueuesForCategory(data, category);

				if (queues) {
					return setQueuesForCategory(
						data,
						category,
						editItem(queues, { id: Number(id), count, lastRefreshedTime: Date.now() }),
					);
				}

				// There is no point update the issue-counts for default queue as there is no queues.
				// It might throwing exceptions if we cannot fetch queue categories.
				// Being defensive here for the rollout, if this code throw any errors, it's the same behavior as the below line.
				if (Number(id) === DEFAULT_QUEUE_ID) {
					return data;
				}

				throw new Error(
					`Cannot edit item issue count because ${category} does not exist in resource for queue id ${id}`,
				);
			});
		},
		editItemFavorite: (id: string, favourite: boolean) => {
			update((data) => {
				const queues = getQueuesForCategory(data, category);

				if (queues) {
					return setQueuesForCategory(
						data,
						category,
						editItem(queues, { id: Number(id), favourite }),
					);
				}

				throw new Error(`Cannot edit item favorite because ${category} does not exist in resource`);
			});
		},
		editVisibilityOfItems: (
			updatedItems: QueueVisibilityPayload[],
			draggableId: number,
			onSuccessCallback: SuccessCallback,
			onErrorCallback: ErrorCallback,
		) => {
			update((data) => {
				const queues = getQueuesForCategory(data, category);
				const { baseUrl, projectKey } = restParams;
				if (queues) {
					updateQueuesVisibility(baseUrl, projectKey, updatedItems)
						.then(() => onSuccessCallback())
						.catch((err: FetchError) => onErrorCallback(getAnalyticsPayload(err)));
					return setQueuesForCategory(data, category, editItems(queues, [...updatedItems]));
				}

				throw new Error(`Cannot edit item favorite because ${category} does not exist in resource`);
			});
		},
		enableRefreshForQueues: (queueIds: string[]) => {
			enableRefreshForQueues(update, { ...restParams, category }, queueIds);
		},
		enableIssueCountForSelectedQueues: (selectedQueueIds: number[]) => {
			update((data) => {
				const queues = getQueuesForCategory(data, category);
				if (queues) {
					return setQueuesForCategory(
						data,
						category,
						enableSelectedQueuesVisibility(selectedQueueIds, queues),
					);
				}

				throw new Error(
					`Cannot enable issue count for selected queues because ${category} does not exist in resource`,
				);
			});
		},
	};

	return actions;
};
