import React, { useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token';
import {
	FireScreenAnalytics,
	ContextualAnalyticsData,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import ApdexActions from '@atlassian/jira-providers-spa-apdex-analytics/src/apdex-actions/index.tsx';
import { appBundleReadyMark } from '@atlassian/jira-providers-spa-apdex-analytics/src/marks';
import { AttachmentDropzoneContainer } from '@atlassian/jira-servicedesk-legacy-bridges-common/src/ui/attachment-dropzone-container/index.tsx';
import { LegacyIssueShortcuts } from '@atlassian/jira-servicedesk-legacy-bridges-common/src/ui/legacy-issue-shortcuts/index.tsx';
import { addLinkInterceptor } from '@atlassian/jira-servicedesk-legacy-bridges-common/src/utils/add-link-interceptor/index.tsx';
import { addNavigationListener } from '@atlassian/jira-servicedesk-legacy-bridges-common/src/utils/add-navigation-listener/index.tsx';
import { getCalendarResources } from '@atlassian/jira-servicedesk-legacy-bridges-common/src/utils/get-calendar-resources/index.tsx';
import { getMomentResources } from '@atlassian/jira-servicedesk-legacy-bridges-common/src/utils/get-moment-resources/index.tsx';
import { addMetaTags } from '@atlassian/jira-servicedesk-legacy-bridges-common/src/utils/meta-tag-support/index.tsx';
import QueuesLegacyIssueView from '@atlassian/jira-servicedesk-queues-legacy-issue-view/src/ui/index.tsx';
import type { Report } from '@atlassian/jira-servicedesk-reports-legacy-issue-view/src/ui/bridge/types.tsx';
import ReportsLegacyIssueView from '@atlassian/jira-servicedesk-reports-legacy-issue-view/src/ui/index.tsx';
import { usePageContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-context/main.tsx';
import SpaStateTransitionStateSubscriber from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';
import WRMDataContainer from '@atlassian/jira-wrm/src/wrm-data-container.tsx';
import { WRMModuleLoader } from '@atlassian/jira-wrm/src/wrm-module-loader/index.tsx';
import { JSMIssueViewLoad, JSMReportsIssueViewLoad } from './performance-analytics';

export type ExternalProps = {
	issueKey: string;
	entity: Report | string; // string is queueId,
	wrmDataForPage: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	};
	locale: Locale;
	pathname: string;
	onNavigation: (path: string) => void;
};

export type Props = ExternalProps;

const getResources = (locale: Locale): string[] => [
	...getMomentResources(locale),
	...[getCalendarResources(locale)],
];

export const Page = (props: Props) => {
	const { onNavigation, entity, issueKey, locale, wrmDataForPage, pathname } = props;
	const outerElement = useRef<HTMLDivElement | null>(null);

	const [{ data: pageContext }] = usePageContext();

	const { projectContext } = pageContext || {};
	const { projectKey } = projectContext || {};
	const metricKey =
		typeof entity === 'string' ? 'servicedesk.queues.issue' : 'servicedesk.reports.custom.issue';

	const metric = typeof entity === 'string' ? JSMIssueViewLoad : JSMReportsIssueViewLoad;

	useEffect(() => {
		addLinkInterceptor(outerElement.current, onNavigation);
		// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
		addNavigationListener(projectKey, outerElement.current, onNavigation, false);
		addMetaTags({ 'atlassian-token': getXsrfToken() }, { shouldIncludeAjsPrefix: false });
	}, [onNavigation, projectKey]);

	return (
		<>
			<AttachmentDropzoneContainer />
			<LegacyIssueShortcuts ref={outerElement} />
			<LegacyStyles ref={outerElement}>
				<WRMDataContainer wrmData={wrmDataForPage} />
				<SpaStateTransitionStateSubscriber>
					{({ isInitialRender }) => (
						<WRMModuleLoader modules={getResources(locale)}>
							{({ loading, error }) => {
								if (error) {
									// If the WRM fails to load here something has gone horribly wrong
									// In this case we want to throw to the JS error boundary to catch and log the error
									fireErrorAnalytics({
										error,
										meta: {
											id: 'ServiceDeskLegacyIssuePage',
											packageName: 'jiraServicedeskLegacyBridges',
										},
									});
									throw error;
								}

								if (loading) {
									return <Spinner />;
								}

								return (
									// @ts-expect-error - TS2769 - No overload matches this call.
									<ApdexActions
										metricKey={metricKey}
										withMarks={[appBundleReadyMark]}
										isExpectedToHaveSsr={false}
										metric={metric}
									>
										{({ submit }) => (
											<ContextualAnalyticsData
												sourceName="serviceDeskLegacyIssuePage"
												sourceType={SCREEN}
												attributes={{ pageKey: metricKey }}
											>
												{!loading && <FireScreenAnalytics />}
												{typeof entity === 'string' ? (
													<QueuesLegacyIssueView
														queueId={entity}
														issueKey={issueKey}
														onSubmitApdex={submit}
														isInitialRender={isInitialRender}
														pathname={pathname}
													/>
												) : (
													<ReportsLegacyIssueView
														report={entity}
														issueKey={issueKey}
														onSubmitApdex={submit}
														isInitialRender={isInitialRender}
														pathname={pathname}
													/>
												)}
											</ContextualAnalyticsData>
										)}
									</ApdexActions>
								);
							}}
						</WRMModuleLoader>
					)}
				</SpaStateTransitionStateSubscriber>
			</LegacyStyles>
		</>
	);
};

export default Page;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LegacyStyles = styled.div({
	/* AtlasKit's CSS Reset sets the line-height in the body
    to a different value to what the legacy code is expecting */
	lineHeight: '20px',

	/* Undoes the AK CSS Reset */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	dl: {
		paddingLeft: 0,
	},

	/* From custom-queues.less */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.issue-header-content .aui-page-header-image': {
		display: 'none',
	},
});
