import React, { type ReactNode } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import type { ColumnId } from '../../../../../../model/columns';

type CriticalErrorBoundaryType = {
	children: ReactNode;
	columnId: ColumnId;
	columnsToIgnore: ColumnId[] | undefined;
	ErrorInlineMessage: ({ columnId }: { columnId: ColumnId }) => ReactNode;
};

export const ConditionalErrorBoundary = ({
	children,
	columnId,
	columnsToIgnore,
	ErrorInlineMessage,
}: CriticalErrorBoundaryType) =>
	!columnsToIgnore?.includes(columnId) ? (
		<JSErrorBoundary
			id="virtual-table-row-additional-column"
			fallback={() => <ErrorInlineMessage columnId={columnId} />}
		>
			{children}
		</JSErrorBoundary>
	) : (
		<>{children}</>
	);
