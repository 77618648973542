import React from 'react';
import { forgeModulesConcurrentLoadTime } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics/main.tsx';
import type { Section } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { useGetLinkProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/common/utils/get-link-props/index.tsx';
import {
	ExpandableMenuItem,
	ExpandableMenuItemTrigger,
	ExpandableMenuItemContent,
	MenuLinkItem,
	MenuList,
	MenuSection,
	Divider,
	MenuListItem,
} from '@atlassian/navigation-system';
import type { ForgeItem as ForgeItemProps } from '../../../types';
import { ForgeIcon } from '../forge-icon';

type ItemProps = {
	id: string;
	url: string;
	section: Section;
	showIcon: boolean;
};

const createPageUrl = (extensionBaseUrl: string, route: string) =>
	route ? `${extensionBaseUrl}/${String(route).trim().replace(/^\/+/, '')}` : extensionBaseUrl;

const ForgeItemExpandableWithHeader = (props: ItemProps) => {
	const { getLinkProps } = useGetLinkProps();
	const { showIcon, id: itemId, url, section } = props;

	return (
		<MenuList>
			<ExpandableMenuItem isDefaultExpanded>
				<ExpandableMenuItemTrigger>{section.header}</ExpandableMenuItemTrigger>
				<ExpandableMenuItemContent>
					{section.pages.map((page) => (
						<MenuLinkItem
							key={page.route}
							elemBefore={showIcon ? <ForgeIcon url={page.icon} /> : undefined}
							{...getLinkProps(createPageUrl(url, page.route))}
							analytics={{ itemId }}
							onClick={() => {
								forgeModulesConcurrentLoadTime(itemId).start({ startTime: performance.now() });
							}}
						>
							{page.title}
						</MenuLinkItem>
					))}
				</ExpandableMenuItemContent>
			</ExpandableMenuItem>
		</MenuList>
	);
};

const ForgeItemExpandableWithoutHeader = (props: ItemProps) => {
	const { getLinkProps } = useGetLinkProps();
	const { showIcon, id: itemId, url, section } = props;

	return (
		<MenuList>
			{section.pages.map((page) => (
				<MenuLinkItem
					key={page.route}
					elemBefore={showIcon ? <ForgeIcon url={page.icon} /> : undefined}
					{...getLinkProps(createPageUrl(url, page.route))}
					analytics={{ itemId }}
					onClick={() => {
						forgeModulesConcurrentLoadTime(itemId).start({ startTime: performance.now() });
					}}
				>
					{page.title}
				</MenuLinkItem>
			))}
		</MenuList>
	);
};

export const ForgeItemExpandable = (props: ForgeItemProps) => {
	const { showIcon, id: itemId, url, sections } = props;
	const hasSectionsWithHeader = sections?.some((section) => !!section.header);

	return sections?.map((section: Section, index: number) => (
		<MenuListItem key={index}>
			<MenuSection>
				{typeof section.header === 'string' ? (
					<ForgeItemExpandableWithHeader {...{ id: itemId, section, url, showIcon }} />
				) : (
					<>
						{!hasSectionsWithHeader && index !== 0 && <Divider />}
						<ForgeItemExpandableWithoutHeader {...{ id: itemId, section, url, showIcon }} />
					</>
				)}
			</MenuSection>
		</MenuListItem>
	));
};
