import { UP, DOWN, type RowNavigationDirection } from '../../../model/navigation-directions';
import { getNextActiveRowIndex, getPrevActiveRowIndex } from '../../../state/selectors';
import type { State } from '../../../state/types';
import { defineTransaction } from '../../common/transactions';
import { setActiveRow } from '../../items/active/set/action.tsx';
import { NAVIGATE_TO_ROW, type NavigateToRowAction } from './action';

const getNextRowIndex = (state: State, direction: RowNavigationDirection) => {
	switch (direction) {
		case UP:
			return getPrevActiveRowIndex(state);
		case DOWN:
			return getNextActiveRowIndex(state);
		default:
			throw new Error('illegal value of the direction property.');
	}
};

export default defineTransaction(NAVIGATE_TO_ROW, (action: NavigateToRowAction, state: State) =>
	setActiveRow({
		rowIndex: getNextRowIndex(state, action.payload.direction),
		setFocusOnRow: true, // when we move via keys, we should set the focus on the row
	}),
);
