// pagination would be rendered if the queue has > 10K records.

export { MAX_ISSUES_PER_PAGE } from '@atlassian/jira-issue-table/src/common/table/constants.tsx';

// Used for Queue Header expand/collapse animation
export const ANIMATION_TIMEOUT = 200;

export const EXPERIENCE_PENDING = 'pending' as const;
export const EXPERIENCE_FAILED = 'failed' as const;
export const EXPERIENCE_SUCCEEDED = 'succeeded' as const;

export type ExperienceResult =
	| typeof EXPERIENCE_PENDING
	| typeof EXPERIENCE_FAILED
	| typeof EXPERIENCE_SUCCEEDED;
