import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './ui/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncJsmRepackagingBanner = lazy(() =>
	import(/* webpackChunkName: "async-jsm-repackaging-banner" */ './ui').then(
		(module) => module.JsmRepackagingBanner,
	),
);

export const JsmRepackagingBanner = (props: Props) => (
	<JSErrorBoundary
		id="jsmRepackagingBanner"
		packageName="jiraJsmRepackagingBanner"
		fallback="unmount"
		teamName="jenga"
		sendToPrivacyUnsafeSplunk
	>
		<Placeholder name="jsm-repackaging-banner" fallback={null}>
			<AsyncJsmRepackagingBanner {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
