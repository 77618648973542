import type { Dispatch } from 'redux';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	AnalyticsEventToProps,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { connect } from '../../../../../../common/table-redux';
import type {
	LoadingRowRenderReasonType,
	RowId,
	RowHeightMapping,
} from '../../../../../../model/rows';
import { fireLoadingRowAnalytics } from '../../../../../../ops/rows/analytics/action.tsx';
import { batchUpdateRowHeightMapping } from '../../../../../../ops/rows/row-height/action.tsx';
import { isTableFacade } from '../../../../../../state/consumer/selectors';
import {
	isAutoRowHeight,
	getDefaultRowHeight,
} from '../../../../../../state/consumer/selectors/rows/index.tsx';
import {
	getNumberOfLoadingRowsInDisplayBoundary,
	getVirtualBoundaries,
	getTableContentHeight,
} from '../../../../../../state/selectors/index.tsx';
import { getFlattenedWithAddedRowIds as getVisibleRowIds } from '../../../../../../state/selectors/visible-row-ids/index.tsx';
import type { State } from '../../../../../../state/types';
import List from '../common/base-list';
import VirtualList from './index-dumb';

const mapStateToProps = (state: State) => ({
	rowIds: getVisibleRowIds(state),
	contentHeight: getTableContentHeight(state),
	isAutoRowHeight: isAutoRowHeight(state),
	defaultRowHeight: getDefaultRowHeight(state),
	virtualBoundaries: getVirtualBoundaries(state),
	numberOfLoadingRows: getNumberOfLoadingRowsInDisplayBoundary(state),
	isTableFacade: isTableFacade(state),
	List,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	batchUpdateRowHeightMapping: (rowIds: RowId[], rowHeightMapping: RowHeightMapping) =>
		dispatch(batchUpdateRowHeightMapping(rowIds, rowHeightMapping)),
	onDeferredBuffering: (
		rowsRendered: number,
		rowsDeferred: number,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		fireOperationalAnalytics(analyticsEvent, 'virtualList deferredBuffering', {
			rowsRendered,
			rowsDeferred,
		});
	},
	onLoadingRowUnmount: (
		numberOfLoadingRows: number,
		displayStart: number,
		rowId: RowId,
		timeVisibleFor: number,
		reasonType: LoadingRowRenderReasonType,
		analyticsEvent: UIAnalyticsEvent,
	) =>
		displayStart === 0
			? undefined
			: dispatch(
					fireLoadingRowAnalytics({
						rowId,
						timeVisibleFor,
						numberOfLoadingRows,
						reasonType,
						analyticsEvent,
					}),
				),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(
	AnalyticsEventToProps('virtualList', {
		onDeferredBuffering: 'deferredBuffering',
	})(VirtualList),
);
