import { combineEpics } from 'redux-observable';
import cells from './cells/navigate/epic.tsx';
import columns from './columns';
import header from './header/size/epic';
import hydrate from './hydrate';
import items from './items';
import rows from './rows';
import scrolling from './scrolling';
import size from './table-size/epic';

export default combineEpics(cells, hydrate, size, columns, items, rows, scrolling, header);
