import { connect } from '@atlassian/jira-react-redux';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { setEnabledPracticesAction } from '../../../state/actions/practices';
import ItsmContextListener from './view';

export default connect(null, (dispatch) => ({
	onUpdatePractices: (practiceList: ItsmPractice[]) => {
		dispatch(setEnabledPracticesAction(practiceList));
	},
}))(ItsmContextListener);
