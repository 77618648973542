import { connect } from '../../../../common/table-redux';
import { getColumnConfiguration } from '../../../../state/consumer/selectors/columns/index.tsx';
import { getChangeHiddenColumnsCallback } from '../../../../state/consumer/selectors/index.tsx';
import { getHeaderHeight } from '../../../../state/internal/selectors';
import type { State } from '../../../../state/types';
import ColumnConfiguration from './index-dumb';

export default connect(
	(state: State) => ({
		columnConfiguration: getColumnConfiguration(state),
		headerHeight: getHeaderHeight(state),
		onChangeHiddenColumnsRequested: getChangeHiddenColumnsCallback(state),
	}),
	{},
)(ColumnConfiguration);
