import isEqual from 'lodash/isEqual';
import {
	assocUnsafe as assoc,
	chainUnsafe as chain,
	dissocUnsafe as dissoc,
	updateInUnsafe as updateIn,
} from '@atlassian/jira-common-icepick/src/utils/unsafe/index.tsx';
import type { SortedQueue } from '../../../../model';
import { LOAD_ISSUES_SUCCESS, type LoadIssuesSuccessAction } from '../../../actions/issue';
import { SET_QUEUE, type SetQueueAction } from '../../../actions/queue';
import {
	SORT_ORDER_CHANGED,
	SORT_ORDER_OPTIMISTIC_UPDATE,
	RESET_SORT_ORDER,
	type SortOrderChangedAction,
	type SortOrderOptimisticUpdateAction,
	type ResetSortOrderAction,
} from '../../../actions/table';

type Actions =
	| SetQueueAction
	| LoadIssuesSuccessAction
	| SortOrderChangedAction
	| SortOrderOptimisticUpdateAction
	| ResetSortOrderAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: SortedQueue | null | undefined, action: Actions) => {
	switch (action.type) {
		case SET_QUEUE: {
			return action.payload.queue;
		}
		case LOAD_ISSUES_SUCCESS: {
			const {
				loadedIssuesResponse: { columns, isUsingDefaultSorting },
			} = action.payload;
			const newState = chain(state);

			if (state && !isEqual(columns, state.columns)) {
				newState.assoc('columns', columns);
			}

			return isUsingDefaultSorting ? newState.dissoc('manualSorting').value() : newState.value();
		}
		case SORT_ORDER_CHANGED: {
			return assoc(state, 'manualSorting', action.payload);
		}
		case SORT_ORDER_OPTIMISTIC_UPDATE: {
			const { sortedBy, sortOrder } = action.payload;

			return updateIn(state, ['columns'], (columns) =>
				// @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS7006 - Parameter 'column' implicitly has an 'any' type.
				columns.map((column) =>
					assoc(column, 'currentSorting', column.id === sortedBy ? sortOrder : undefined),
				),
			);
		}
		case RESET_SORT_ORDER: {
			return dissoc(state, 'manualSorting');
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
