import { connect } from '../../../../../../../../common/table-redux';
import type { ColumnId } from '../../../../../../../../model/columns';
import { isVisibleCoreColumn } from '../../../../../../../../state/consumer/selectors/columns/index.tsx';
import type { State } from '../../../../../../../../state/types';
import ChildBar from './child-bar';
import ColumnChildOperationIndicator from './column-child-operation';
import ColumnSortOperationIndicator from './column-sort-operation';
import DumbColumn from './index-dumb';

type Props = {
	id: ColumnId;
};

export default connect(
	(state: State, props: Props) => ({
		ChildBar,
		ColumnChildOperationIndicator,
		ColumnSortOperationIndicator,
		isCoreColumn: isVisibleCoreColumn(state, props.id),
	}),
	{},
)(DumbColumn);
