import { connect } from '@atlassian/jira-react-redux';
import type { QueueCategory } from '@atlassian/jira-servicedesk-queues-common/src/model';
import {
	getBaseUrl,
	getProjectKey,
	getProjectName,
} from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props';
import type { BaseUrl, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { State } from '../../../state/reducers/types';
import { getCategory } from '../../../state/selectors/queue';
import QueuesBreadcrumb from './view';

type StateProps = {
	projectKey: ProjectKey;
	baseUrl: BaseUrl;
	projectName: string;
	category: QueueCategory;
};

export default connect(
	(state: State): StateProps => ({
		// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
		projectKey: getProjectKey(state),
		// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
		projectName: getProjectName(state),
		baseUrl: getBaseUrl(state),
		category: getCategory(state),
	}),
	{},
)(QueuesBreadcrumb);
