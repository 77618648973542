import memoizeOne from 'memoize-one';
import { fireOperationalAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-operational-event.tsx';
import { connect } from '../../common/table-redux';
import { isRowLoading } from '../../state/consumer/selectors/rows/index.tsx';
import { getTableWidthWithoutScrollbarOffset } from '../../state/internal/selectors.tsx';
import {
	hasHorizontalScrollbar,
	hasVerticalScrollbar,
	getTableContentWidth,
} from '../../state/selectors/index.tsx';

import { getFlattenedWithAddedRowIds as getVisibleRowIds } from '../../state/selectors/visible-row-ids/index.tsx';
import type { State } from '../../state/types';
import Content from './content';
import Header from './header';
import TableMain from './index-dumb';
import HorizontalScrollOverlay from './overlays/horizontal-scroll/index.tsx';
import ResizeColumnOverlay from './overlays/resize-column/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToPropsFactory = (): any => {
	const onTableChangeFactory = memoizeOne(
		(
			containerWidth: number,
			tableHasHorizontalScrollbar: boolean,
			tableHasVerticalScrollbar: boolean,
		) =>
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(analyticsEvent: any) => {
				fireOperationalAnalytics(analyticsEvent, {
					containerType: 'virtualTable',
					attributes: {
						hasHorizontalScrollbar: tableHasHorizontalScrollbar,
						hasVerticalScrollbar: tableHasVerticalScrollbar,
						containerWidth,
					},
				});
			},
	);
	return (state: State) => {
		const rowIds = getVisibleRowIds(state);
		const firstRowId = rowIds[0];
		const containerWidth = getTableWidthWithoutScrollbarOffset(state);
		const tableHasHorizontalScrollbar = hasHorizontalScrollbar(state);
		const tableHasVerticalScrollbar = hasVerticalScrollbar(state);

		return {
			rowIds,
			width: getTableContentWidth(state),
			containerWidth,
			hasHorizontalScrollbar: tableHasHorizontalScrollbar,
			hasVerticalScrollbar: tableHasVerticalScrollbar,
			hasLoadingRows: isRowLoading(state, firstRowId),
			Header,
			Content,
			ResizeColumnOverlay,
			HorizontalScrollOverlay,
			onTableChange: onTableChangeFactory(
				containerWidth,
				tableHasHorizontalScrollbar,
				tableHasVerticalScrollbar,
			),
		};
	};
};

export default connect(mapStateToPropsFactory, {})(TableMain);
