// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ReactNode, type ElementRef } from 'react';
import noop from 'lodash/noop';
import type Scrollbars from 'react-custom-scrollbars';
import { StyledScrollbars } from './styled';

export type ScrollValues = {
	top: number;
	left: number;
	clientWidth: number;
	clientHeight: number;
	scrollWidth: number;
	scrollHeight: number;
	scrollLeft: number;
	scrollTop: number;
};

export type ScrollElementProps = {
	ref: (arg1: HTMLElement | undefined | null) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	style: any;
};

export type ScrollbarsRef = ElementRef<typeof Scrollbars> | null;

type Props = {
	className?: string;
	onScrollbarsRef: (element: ScrollbarsRef) => void;
	onScrollFrame: (scrollValues: ScrollValues) => void;
	onScrollStart: () => void;
	onScrollStop: () => void;
	children: ReactNode;
	height?: number;
};

// eslint-disable-next-line jira/react/no-class-components
export default class CustomScrollbars extends PureComponent<Props> {
	static defaultProps = {
		className: '',
		onScrollbarsRef: noop,
		onScrollFrame: noop,
		onScrollStart: noop,
		onScrollStop: noop,
	};

	renderVerticalTrack = (props: ScrollElementProps) => (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div ref={props.ref} style={props.style} className="track-vertical" />
	);

	renderVerticalThumb = (props: ScrollElementProps) => (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div ref={props.ref} style={props.style} className="thumb-vertical" />
	);

	renderHorizontalTrack = (props: ScrollElementProps) => (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div ref={props.ref} style={props.style} className="track-horizontal" />
	);

	renderHorizontalThumb = (props: ScrollElementProps) => (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div ref={props.ref} style={props.style} className="thumb-horizontal" />
	);

	render() {
		const {
			className,
			onScrollbarsRef,
			onScrollFrame,
			onScrollStart,
			onScrollStop,
			children,
			height,
		} = this.props;

		/* className is passed down to enable styled component composition */
		return (
			<StyledScrollbars
				style={{ height: `${height}px` }}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={className}
				ref={onScrollbarsRef}
				onScrollFrame={onScrollFrame}
				onScrollStart={onScrollStart}
				onScrollStop={onScrollStop}
				renderTrackVertical={this.renderVerticalTrack}
				renderThumbVertical={this.renderVerticalThumb}
				renderTrackHorizontal={this.renderHorizontalTrack}
				renderThumbHorizontal={this.renderHorizontalThumb}
				hideTracksWhenNotNeeded
			>
				{children}
			</StyledScrollbars>
		);
	}
}
