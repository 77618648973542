import { Component } from 'react';
import { QUEUES_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';
import type { Items } from '@atlassian/jira-favourite-change-provider/src/model/types.tsx';
import { toQueueIdString } from '@atlassian/jira-servicedesk-queues-common/src/model';
import type { FavoriteSubscriberProps } from './types';

// eslint-disable-next-line jira/react/no-class-components
export default class FavoriteSubscriber extends Component<FavoriteSubscriberProps> {
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	static setFavorite() {}

	onFavoritesChanged = (items: Items) => {
		const queueItemsObj = items[QUEUES_ITEM_TYPE];
		if (!queueItemsObj) {
			return;
		}

		// Object.values gives Array<mixed> according to Flow, which is wrong.
		// https://github.com/facebook/flow/issues/2221
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const queueItems: any = Object.values(queueItemsObj);
		// @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
		queueItems.forEach((item) =>
			this.props.onFavoriteItemChanged(toQueueIdString(item.id), item.value),
		);
	};

	render() {
		return null;
	}
}
