import type { MiddlewareAPI } from 'redux';
import { batchActions, type BatchAction } from 'redux-batched-actions';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import fireErrorAnalytics, {
	getStatusCodeGroup,
} from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { ErrorResponse } from '@atlassian/jira-servicedesk-common/src/utils/fetch';
import 'rxjs/add/observable/of';
import { withConcurrencyLimiting } from '../../../common/rxjs-util/concurrency-limiter';
import {
	LOAD_ISSUES_ACTION_SOURCE_FILTER,
	LOAD_ISSUES_ACTION_SOURCE_POLL,
	LOAD_ISSUES_ACTION_SOURCE_VIEW,
} from '../../../model';
import getIssueData from '../../../services/issue/get';
import { filterBadQueryAction } from '../../../state/actions/filter';
import {
	LOAD_ISSUES_INTERNAL,
	loadIssuesFailureAction,
	loadIssuesSuccessAction,
} from '../../../state/actions/issue';
import type { Action } from '../../../state/actions/types';
import { restartPollAction } from '../../../state/actions/update-metadata';
import type { State } from '../../../state/reducers/types';
import { getIsNewFilterQueryPending } from '../../../state/selectors/filter';
import { getIssueCacheSize } from '../../../state/selectors/issues';
import { getQueueId } from '../../../state/selectors/queue';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action | BatchAction>, store: MiddlewareAPI<State>) =>
	withConcurrencyLimiting(action$.ofType(LOAD_ISSUES_INTERNAL), 3, (action) => {
		const state: State = store.getState();
		const queueId = getQueueId(state);

		const shouldFireGenericErrorAnalytics = (error: ErrorResponse) => {
			if (
				getStatusCodeGroup(new FetchError(error.statusCode)) === 'unknown' ||
				isClientFetchError(error)
			) {
				return false;
			}
			return true;
		};

		const sendGenericErrorAnalytics = (error: ErrorResponse) => {
			fireErrorAnalytics({
				meta: {
					id: 'jsmIssueListLoading',
					packageName: 'jiraServicedeskQueuesAgentView',
					teamName: 'jsd-shield',
				},
				sendToPrivacyUnsafeSplunk: true,
				error: new FetchError(error.statusCode),
				attributes: {
					reasonKey: error.response.reasonKey,
					reasonCode: error.response.reasonCode,
				},
			});
		};

		const sendGenericErrorAnalyticsConditionally = (error: ErrorResponse) => {
			if (shouldFireGenericErrorAnalytics(error)) {
				sendGenericErrorAnalytics(error);
			}
		};

		const sendSearchErrorAnalytics = (error: ErrorResponse) => {
			fireErrorAnalytics({
				meta: {
					id: 'jsmIssueListLoadingFilter',
					packageName: 'jiraServicedeskQueuesAgentView',
					teamName: 'jsd-shield',
				},
				sendToPrivacyUnsafeSplunk: true,
				error: new FetchError(error.statusCode),
				attributes: {
					hasPassed: false,
				},
			});
		};

		const shouldFireSearchErrorAnalytics = (error: ErrorResponse): boolean => {
			const statusCodeGroup = getStatusCodeGroup(new FetchError(error.statusCode));
			return (
				action.payload.source === LOAD_ISSUES_ACTION_SOURCE_FILTER &&
				(statusCodeGroup === '5xx' || statusCodeGroup === '4xx') &&
				!isClientFetchError(error)
			);
		};

		const sendErrorAnalytics = (error: ErrorResponse) => {
			if (shouldFireSearchErrorAnalytics(error)) {
				sendSearchErrorAnalytics(error);
			}

			sendGenericErrorAnalyticsConditionally(error);
		};

		return (
			getIssueData(
				action.payload.startIndex,
				action.payload.endIndex,
				[LOAD_ISSUES_ACTION_SOURCE_POLL, LOAD_ISSUES_ACTION_SOURCE_VIEW].includes(
					// @ts-expect-error - TS2339 - Property 'payload' does not exist on type 'Action'.
					action.payload.source,
				),
				action.payload.bypassCache,
				state,
				action.payload.source === LOAD_ISSUES_ACTION_SOURCE_FILTER,
			)
				.flatMap((result) =>
					Observable.of(
						batchActions([
							restartPollAction(),
							loadIssuesSuccessAction(
								result,
								queueId,
								action.payload.startIndex,
								action.payload.source,
							),
						]),
					),
				)
				// @ts-expect-error - TS2345 - Argument of type '(error: ErrorResponse) => Observable<BatchAction> | Observable<RestartPollAction>' is not assignable to parameter of type '(err: any, caught: Observable<BatchAction>) => ObservableInput<BatchAction>'.
				.catch((error: ErrorResponse) => {
					if (error.statusCode === 304) {
						return Observable.of(restartPollAction());
					}
					if (
						// Bad query request
						error.statusCode === 400 &&
						action.payload.source === LOAD_ISSUES_ACTION_SOURCE_FILTER
					) {
						const isNewFilterQueryPendingAction = getIsNewFilterQueryPending(state);

						return Observable.of(
							batchActions([
								restartPollAction(),
								filterBadQueryAction(isNewFilterQueryPendingAction),
							]),
						);
					}
					sendErrorAnalytics(error);

					return Observable.of(
						batchActions([
							restartPollAction(),
							loadIssuesFailureAction(
								action.payload.source,
								error.response,
								error.statusCode,
								getIssueCacheSize(state) === 0,
								error.traceId,
							),
						]),
					);
				})
		);
	});
