import { chain } from 'icepick';
import isNumber from 'lodash/isNumber';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import getMeta from '@atlassian/jira-get-meta';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClient } from '@atlassian/jira-product-analytics-web-client';
import {
	getLimit,
	MAX_QUEUES_PER_SD,
} from '@atlassian/jira-servicedesk-common/src/ui/components/limit-data';
import type { Page } from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { getQueuesForCategory, setQueuesForCategory } from '../../../../../common/utils';
import type { Updater } from '../../types';

type QueuesMap = {
	[key: number]: Page;
};

type QueuesPerGroup = {
	[key: string]: Page[];
};

export type IssueCountPerQueue = {
	[key: number]: number;
};

const ERROR_DB_TIMEOUT = 'error.db-timeout' as const;
const ERROR_UNKNOWN = 'error.unknown' as const;

export const ISSUECOUNTS_RESULT = {
	FAILED: 'failed',
	SUCCESS: 'success',
	PENDING: 'pending',
} as const;

export type ISSUECOUNTS_RESULT = (typeof ISSUECOUNTS_RESULT)[keyof typeof ISSUECOUNTS_RESULT];

const MAX_ISSUECOUNT_THRESHOLD = 1000;

type IssueCounts = {
	[key: number]: {
		issueCount?: number;
		errorCode?: typeof ERROR_DB_TIMEOUT | typeof ERROR_UNKNOWN;
		result?: ISSUECOUNTS_RESULT;
	};
};

export type IssueCountResponse = {
	issueCounts: IssueCounts;
};

export const fireManualOperationalAnalytics = (payload: {
	action: string;
	actionSubject: string;
	source: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	attributes?: any;
}) => getAnalyticsWebClient().getInstance().sendOperationalEvent(payload);

const filterOutHiddenQueues = (queues: Page[]) =>
	queues.filter((q) => !(q.canBeHidden === undefined ? false : q.canBeHidden) || q.favourite);

const filterOutBadQueues = (queues: Page[]) => queues.filter((q) => !q.shouldSkipIssueCountRefresh);

const getIssueCountPerGroup = (issueCounts: IssueCounts): IssueCountPerQueue =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.keys(issueCounts).reduce<Record<string, any>>((curr, queueId) => {
		const res = curr;
		if (issueCounts[Number(queueId)]) {
			if (
				issueCounts[Number(queueId)].issueCount ||
				issueCounts[Number(queueId)].issueCount === 0
			) {
				res[Number(queueId)] = issueCounts[Number(queueId)].issueCount;
			}
		}
		return res;
	}, {});

const getQueuesPerGroup = (queues: Page[], skipHiddenQueues: boolean): QueuesPerGroup => {
	const starredQueues: Page[] = [];
	const defaultQueues: Page[] = [];
	let toGroupQueues = queues;
	let hasSkippedQueues = false;
	if (skipHiddenQueues === true) {
		toGroupQueues = filterOutHiddenQueues(queues);
	}
	toGroupQueues = filterOutBadQueues(toGroupQueues);

	let queueByGroups: QueuesPerGroup = {};
	toGroupQueues.forEach((queue) => {
		if (isNumber(queue.count) && queue.count > MAX_ISSUECOUNT_THRESHOLD) {
			hasSkippedQueues = true;
			return;
		}
		if (queue.favourite) {
			starredQueues.push(queue);
		} else {
			defaultQueues.push(queue);
		}
	});
	queueByGroups = {
		starredQueues,
		defaultQueues,
	};

	if (hasSkippedQueues) {
		fireManualOperationalAnalytics({
			source: 'refreshQueueIssueCountAction',
			actionSubject: 'queueIssueCountRefresh',
			action: 'skipped',
		});
	}

	return queueByGroups;
};

const getQueuesSortByLastRefreshedTime = (queuesBeforeSort: QueuesPerGroup): Page[] => {
	let queuesSortByLastRefreshedTime: Page[] = [];
	const queuesSortByGroup = [...queuesBeforeSort.starredQueues, ...queuesBeforeSort.defaultQueues];
	queuesSortByLastRefreshedTime = sortBy(queuesSortByGroup, ['lastRefreshedTime']);
	return queuesSortByLastRefreshedTime;
};

export const fireErrorAnalyticsForRefreshCountFailure = (error: Error) => {
	fireErrorAnalytics({
		meta: {
			id: 'refreshQueueIssueCountAction',
			packageName: 'jiraServicedeskCategorizedQueuesStore',
			teamName: 'jsd-shield',
		},
		error,
	});
};

const isIssueCountAlreadyFetched = (queue: Page): boolean => queue.count !== undefined;

const getSkippedQueues = (issueCounts: IssueCounts, queuesMap: QueuesMap): number[] =>
	Object.keys(issueCounts)
		.map((id) => Number(id))
		.filter(
			(queueId) =>
				issueCounts[queueId].result === ISSUECOUNTS_RESULT.PENDING &&
				!isIssueCountAlreadyFetched(queuesMap[queueId]),
		);

const fetchIssueCountForQueueGroup = async (
	projectKey: string,
	queueIds: number[],
): Promise<IssueCountResponse> =>
	performPostRequest(`/rest/servicedesk/1/servicedesk/${projectKey}/queues/issue-counts/refresh`, {
		body: JSON.stringify(queueIds),
	});

const getQueueGroupData = (
	queueIds: number[],
	issueCounts: IssueCounts,
	queuesMap: QueuesMap,
	batchStartProcessingTime: number,
) => {
	const queueRefreshData = {
		minLastRefreshTimeFromCurrentBatch: Number.MAX_VALUE,
		queueCountChanges: 0,
	};
	return queueIds.reduce((changes, queueId) => {
		if (issueCounts[queueId]) {
			if (issueCounts[queueId].issueCount !== undefined || issueCounts[queueId].issueCount === 0) {
				const queue = queuesMap[queueId];
				const currentQueueLastRefreshedTime =
					queue.lastRefreshedTime !== undefined && queue.lastRefreshedTime !== 0
						? queue.lastRefreshedTime
						: batchStartProcessingTime;
				const minLastRefreshTimeFromCurrentBatch = Math.min(
					changes.minLastRefreshTimeFromCurrentBatch,
					currentQueueLastRefreshedTime,
				);
				const queueCountChanges =
					queue.count !== issueCounts[queueId].issueCount
						? changes.queueCountChanges + 1
						: changes.queueCountChanges;
				return {
					minLastRefreshTimeFromCurrentBatch,
					queueCountChanges,
				};
			}
		}
		return changes;
	}, queueRefreshData);
};

const fetchAndUpdateCount = async (
	baseUrl: string,
	projectKey: string,
	queueIds: number[],
	category: ItsmPractice,
	analyticsEvent?: UIAnalyticsEvent,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	update: Updater,
	batchStartProcessingTime: number,
	retryCallback: (queues: number[]) => void,
) => {
	if (queueIds.length === 0) {
		return;
	}
	let maxQueuesPerSd = await getLimit(MAX_QUEUES_PER_SD);
	if (maxQueuesPerSd === null) {
		maxQueuesPerSd = queueIds.length;
	}
	const queueIdsToFetch: Array<number> = queueIds.slice(0, maxQueuesPerSd);

	const startTime = Date.now();
	await fetchIssueCountForQueueGroup(projectKey, queueIdsToFetch)
		.then(({ issueCounts }) => {
			update((data) => {
				const queuesMap: QueuesMap = keyBy(getQueuesForCategory(data, category), 'id');

				const skippedQueues = getSkippedQueues(issueCounts, queuesMap);

				if (skippedQueues.length > 0) {
					retryCallback(skippedQueues);
				}

				const endTime = Date.now();
				let queueCountChanges = 0;
				let minLastRefreshTimeFromCurrentBatch = 0;
				let numberOfBadQueues = 0;
				const queueRefreshData = getQueueGroupData(
					queueIds,
					issueCounts,
					queuesMap,
					batchStartProcessingTime,
				);
				queueCountChanges = queueRefreshData.queueCountChanges;
				minLastRefreshTimeFromCurrentBatch = queueRefreshData.minLastRefreshTimeFromCurrentBatch;

				queueIds.forEach((queueId) => {
					const queue = queuesMap[queueId];
					if (issueCounts[queueId]) {
						if (
							issueCounts[queueId].issueCount !== undefined ||
							issueCounts[queueId].issueCount === 0
						) {
							queuesMap[queueId] = {
								...queue,
								count: issueCounts[queueId].issueCount,
								lastRefreshedTime: endTime,
								shouldSkipIssueCountRefresh: false,
							};
						} else if (issueCounts[queueId].errorCode === ERROR_DB_TIMEOUT) {
							numberOfBadQueues += 1;
							queuesMap[queueId] = {
								...queue,
								shouldSkipIssueCountRefresh: true,
							};
						}
					}
				});

				if (analyticsEvent) {
					const issueCountPerGroup = getIssueCountPerGroup(issueCounts);
					const queueIssueCounts = Object.values(issueCountPerGroup).map((queueCount) =>
						Number(queueCount),
					);

					const jiraShard = getMeta('ajs-shard') || '';
					if (queueIssueCounts.length === 0) {
						fireOperationalAnalytics(
							analyticsEvent,
							'navigationSession poorPerformanceQueuesCountsInfo',
							{
								category,
								queuesRequested: queueIdsToFetch.length,
								maxQueuesPerSd,
								timeToFetchIssueCount: endTime - startTime,
								timeSinceBatchStart: endTime - batchStartProcessingTime,
								jiraShard,
								browserTabVisibilityState: document.visibilityState,
								numberOfBadQueues,
							},
						);
					} else {
						fireOperationalAnalytics(analyticsEvent, 'navigationSession queuesCountsInfo', {
							category,
							queues: queueIssueCounts.length,
							queuesRequested: queueIdsToFetch.length,
							maxQueuesPerSd,
							maxIssueCount: Math.max(...queueIssueCounts),
							timeToFetchIssueCount: endTime - startTime,
							timeBetweenQueueRefresh: endTime - minLastRefreshTimeFromCurrentBatch,
							timeSinceBatchStart: endTime - batchStartProcessingTime,
							noOfQueuesWithUpdatedCount: queueCountChanges,
							jiraShard,
							browserTabVisibilityState: document.visibilityState,
							numberOfBadQueues,
						});
					}
				}

				return setQueuesForCategory(
					data,
					category,
					// Object.values is typed as Mixed[] and makes flow unhappy
					sortBy(values(queuesMap), 'order'),
				);
			});
		})
		.catch((error) => {
			fireErrorAnalyticsForRefreshCountFailure(error);
		});
};

const updateLoadingState = (category: ItsmPractice, queues: Page[], update: Updater) => {
	update((data) =>
		data
			? chain(data)
					.setIn([category, 'hasRequestedCount'], true)
					.setIn([category, 'isCountLoading'], false)
					.value()
			: null,
	);
};

export const updateIssueCountForQueues = async (
	baseUrl: string,
	projectKey: string,
	category: ItsmPractice,
	queues: Page[],
	skipHiddenQueues: boolean,
	update: Updater,
	retryCallback: (queues: number[]) => void,
	analyticsEvent?: UIAnalyticsEvent,
) => {
	const queuesPerGroup = getQueuesPerGroup(queues, skipHiddenQueues);
	const queuesSortByLastRefreshedTime = getQueuesSortByLastRefreshedTime(queuesPerGroup);
	const queueIds = queuesSortByLastRefreshedTime.map((x) => x.id);
	const batchStartProcessingTime = Date.now();
	try {
		// fetching the result in one call
		await fetchAndUpdateCount(
			baseUrl,
			projectKey,
			queueIds,
			category,
			analyticsEvent,
			update,
			batchStartProcessingTime,
			retryCallback,
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		updateLoadingState(category, queues, update);
		fireErrorAnalyticsForRefreshCountFailure(error);
		throw error;
	}

	updateLoadingState(category, queues, update);
};
