import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import {
	type Action,
	createStore,
	createHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import {
	setQueueGroupsStateInLocalStorage,
	getQueueGroupsStateFromLocalStorage,
} from '../local-storage';

// TODO: go/restrict-enums
// TODO - Remove ENUMS https://jplat.jira.atlassian.cloud/browse/FSN-4507
// eslint-disable-next-line no-restricted-syntax
export enum QueueListSection {
	STARRED,
	PRIORITY_GROUP,
	ALL_QUEUES,
}

type SidebarSelection = {
	selectedGroupId: string | undefined;
	selectedQueueSection: QueueListSection | undefined;
};

export type State = Record<ItsmPractice, SidebarSelection>;

export type Props = {
	projectKey: string;
};

export const actions = {
	updateCurrentCategorySelectedGroup:
		(
			projectKey: string,
			practice: ItsmPractice,
			selectedGroupId: string | undefined,
		): Action<State> =>
		({ setState, getState }) => {
			setState({
				...getState(),
				...(getState()[practice]
					? { [practice]: { ...getState()[practice], selectedGroupId } }
					: { [practice]: { selectedGroupId } }),
			});
			setQueueGroupsStateInLocalStorage(projectKey, getState());
		},

	updatePathCategorySelectedQueueSection:
		(
			projectKey: string,
			practice: ItsmPractice,
			selectedQueueSection: QueueListSection,
		): Action<State> =>
		({ setState, getState }) => {
			setState({
				...getState(),
				...(getState()[practice]
					? { [practice]: { ...getState()[practice], selectedQueueSection } }
					: { [practice]: { selectedQueueSection } }),
			});
			setQueueGroupsStateInLocalStorage(projectKey, getState());
		},
};

type Actions = typeof actions;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const initialState: State = {} as State;

const Store = createStore<State, Actions>({
	name: 'jsm-navigation-categories-store',
	initialState,
	actions,
});

export const NavigationCategoryContainer = createContainer<State, Actions, Props>(Store, {
	onInit:
		() =>
		({ setState }, { projectKey }) => {
			const stateFromStorage = getQueueGroupsStateFromLocalStorage(projectKey);
			if (stateFromStorage) {
				setState(stateFromStorage);
			}
		},
});

export const useCategoriesSelection = createHook(Store);

export const useSelectedGroupIdByCategory = createHook(Store, {
	selector: (state: State, category: ItsmPractice) => state[category]?.selectedGroupId,
});

export const useSelectedQueueSectionByCategory = createHook(Store, {
	selector: (state: State, category: ItsmPractice) => state[category]?.selectedQueueSection,
});
