import React, { memo, useEffect } from 'react';
import { styled as ReactCompiled } from '@compiled/react';
import type { AnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources';
import type { IssueDeleteCallbackArgs } from '@atlassian/jira-issue-view-foundation/src/issue-actions/delete-issue/types.tsx';
import type { ChangeEvent } from '@atlassian/jira-issue-view-model/src/change-type';
import { JiraDetailPanel } from '@atlassian/jira-layout-controller/src/ui/detail-panel/index.tsx';
import { toIssueKey, type IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import IssueApp from './issue-app';
import { SidebarIssueApp } from './main';
import Resizer from './resizer/view.tsx';

export type Props = {
	issueKey?: IssueKey | null;
	analyticsSource: AnalyticsSource;
	onClose: () => void;
	onChange?: (event: ChangeEvent) => void;
	onIssueDeleteSuccess?: (arg1: IssueDeleteCallbackArgs) => void;
	parentWidth: number;
	setIsResizing: (arg: boolean) => void;
};

const callWhenIdle = (callback: () => void) => {
	if (typeof window.requestIdleCallback === 'function') {
		window.requestIdleCallback(callback);
	} else {
		setTimeout(callback, 3000);
	}
};

export const SidebarIssueView = memo<Props>(
	({
		issueKey,
		onClose,
		analyticsSource,
		onChange,
		onIssueDeleteSuccess,
		parentWidth,
		setIsResizing,
	}: Props) => {
		useEffect(() => {
			// Preload IssueApp here, otherwise it'd be loaded when Sidebar opens up for the first time, which is a bit slow
			callWhenIdle(() => {
				IssueApp.preload();
			});
		}, []);

		return (
			<JiraDetailPanel>
				{(setDetailPanelWidth) => (
					<Resizer
						parentWidth={parentWidth}
						onResize={setDetailPanelWidth}
						setIsResizing={setIsResizing}
					>
						{issueKey && (
							<SidebarWrapper data-testid="servicedesk-queues-agent-view.layout.sidebar-issue-app.wrapper">
								<SidebarIssueApp
									issueKey={toIssueKey(issueKey)}
									onClose={onClose}
									analyticsSource={analyticsSource}
									onChange={onChange}
									onIssueDeleteSuccess={onIssueDeleteSuccess}
								/>
							</SidebarWrapper>
						)}
					</Resizer>
				)}
			</JiraDetailPanel>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SidebarWrapper = ReactCompiled.div({
	height: '100%',
	width: '100%',
});
