import React, { type ReactNode, useMemo, useEffect } from 'react';
import Button from '@atlaskit/button';
import { Box } from '@atlaskit/primitives';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { SkeletonNavMenuItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/common/ui/skeleton-nav-menu-items/main.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { usePathParam } from '@atlassian/jira-router';
import { QueueListSection } from '@atlassian/jira-servicedesk-queues-categorized-store/src/controllers/navigation-category';
import type { NavItem } from '@atlassian/jira-servicedesk-queues-categorized-store/src/types.tsx';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import {
	ExpandableMenuItem,
	ExpandableMenuItemContent,
	ExpandableMenuItemTrigger,
	MenuSection,
} from '@atlassian/navigation-system';
import { useSelectedNavLocation } from '../../../controllers/nav-location';
import { useFilteredCategorizedQueueGroups } from '../common';
import { QueueList } from '../queue-list/main.tsx';
import { messages } from './messages';

export type QueueGroupsSectionProps = {
	projectKey: string;
	currentCategory: ItsmPractice;
	onGroupDataRefresh: () => void;
};

export const QueueGroupsSection = ({
	projectKey,
	currentCategory,
	onGroupDataRefresh,
}: QueueGroupsSectionProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [filteredGroups, isLoading, error] = useFilteredCategorizedQueueGroups(
		projectKey,
		currentCategory,
	);
	const [queueIdFromPath] = usePathParam('queueId');
	const [selectedNavLocation] = useSelectedNavLocation(queueIdFromPath || '');

	const isInvalidSelectedNavLocation = queueIdFromPath && selectedNavLocation.section === undefined;

	const ExpandableGroups = useMemo(
		() => (
			<>
				{filteredGroups.map((group) => (
					<ExpandableMenuItem
						key={group.group.id}
						isDefaultExpanded={selectedNavLocation?.groupId === group.group.id}
					>
						<ExpandableMenuItemTrigger>{group.group.name}</ExpandableMenuItemTrigger>
						<ExpandableMenuItemContent>
							<QueueList
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								queues={group.queues as NavItem[]}
								section={QueueListSection.PRIORITY_GROUP}
								projectKey={projectKey}
								priorityGroupId={group.group.id}
							/>
						</ExpandableMenuItemContent>
					</ExpandableMenuItem>
				))}
			</>
		),
		[filteredGroups, projectKey, selectedNavLocation?.groupId],
	);

	useEffect(() => {
		if (error) {
			fireErrorAnalytics({
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				error: error as Error,
				meta: {
					id: 'jsmQueueGroupsError',
					packageName: 'jiraServicedeskQueuesNav4',
					teamName: 'jsd-shield',
				},
			});
		} else if (filteredGroups && !isLoading && filteredGroups.length > 0) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'jsmQueueGroups rendered', {
				projectKey,
				groupSize: filteredGroups.length,
				category: currentCategory,
				packageName: 'jiraServicedeskQueuesNav4',
				teamName: 'jsd-shield',
			});
		}
	}, [createAnalyticsEvent, currentCategory, error, filteredGroups, isLoading, projectKey]);

	// Render loading state
	if (isLoading || isInvalidSelectedNavLocation) {
		return <SkeletonNavMenuItems count={2} />;
	}

	// Render error state
	if (error) {
		return (
			<MenuSection>
				<Box padding="space.100">
					{formatMessage(messages.fetchPriorityGroupError, {
						button: (buttonLabel: ReactNode) => (
							<Button spacing="none" appearance="link" onClick={onGroupDataRefresh}>
								{buttonLabel}
							</Button>
						),
					})}
				</Box>
			</MenuSection>
		);
	}

	return ExpandableGroups;
};
