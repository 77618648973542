// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import noop from 'lodash/noop';
import CheckIcon from '@atlaskit/icon/glyph/check';
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active';
import { defineMessages } from '@atlassian/jira-intl';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { itemTypes } from '../item-types';
import {
	Wrapper,
	CheckIconWrapper,
	Avatar,
	Content,
	Title,
	LozengeWrapper,
	Description,
} from './styles';
import type { Props } from './types';

// @ts-expect-error - TS2304 - Cannot find name 'ItemType'.
const shouldShowSelect = (type: ItemType) =>
	[itemTypes.CHECKBOX, itemTypes.RADIO].indexOf(type) !== -1;

const messages = defineMessages({
	selected: {
		id: 'virtual-table.table.header.column-configuration.list.flyout.multi-select-list.list.item.selected',
		defaultMessage: 'Item selected',
		description: '',
	},
});

// eslint-disable-next-line jira/react/no-class-components
export class MultiSelectItem extends PureComponent<Props> {
	static defaultProps = {
		type: itemTypes.DEFAULT,
		description: undefined,
		lozenge: undefined,
		avatarUrl: undefined,
		appearance: {},
		isFocused: false,
		isSelected: false,
		isDefaultSelected: false,
		isDisabled: false,
		hasDivider: false,
		onHover: noop,
		onSelect: noop,
	};

	onClick = () => {
		const { isDisabled, onSelect } = this.props;

		if (!isDisabled) {
			onSelect();
		}
	};

	renderContent() {
		const { avatarUrl, content, lozenge, type } = this.props;

		return (
			<Content>
				{shouldShowSelect(type) && this.renderSelect()}
				{avatarUrl && <Avatar src={avatarUrl} />}
				<Title>{content}</Title>
				{lozenge && <LozengeWrapper>{lozenge}</LozengeWrapper>}
			</Content>
		);
	}

	renderSelect() {
		const {
			type,
			isSelected,
			isDefaultSelected,
			intl: { formatMessage },
		} = this.props;

		return (
			<CheckIconWrapper isDefaultSelected={isDefaultSelected}>
				{(isSelected || isDefaultSelected) &&
					(type === itemTypes.CHECKBOX ? (
						<CheckIcon size="small" label={formatMessage(messages.selected)} />
					) : (
						// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
						<PresenceActiveIcon size="small" label={formatMessage(messages.selected)} />
					))}
			</CheckIconWrapper>
		);
	}

	renderDescription() {
		const { avatarUrl, description, type } = this.props;

		// @ts-expect-error - TS2365 - Operator '+' cannot be applied to types 'boolean' and 'boolean'.
		const shouldIndent = shouldShowSelect(type) + !!avatarUrl;

		return <Description shouldIndent={shouldIndent}>{description}</Description>;
	}

	render() {
		const { appearance, description, hasDivider, onHover, isFocused, isDisabled, type, id } =
			this.props;

		return (
			<Wrapper
				type={type}
				onClick={this.onClick}
				isFocused={isFocused}
				isDisabled={isDisabled}
				onMouseMove={onHover}
				hasDivider={hasDivider}
				appearance={appearance}
				data-testid={`common.components.virtual-table.table.header.column-configuration.list.flyout.multi-select-list.list.item.wrapper-${id}`}
			>
				{this.renderContent()}
				{description && this.renderDescription()}
			</Wrapper>
		);
	}
}

export default injectIntl(MultiSelectItem);
