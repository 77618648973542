import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { usePathParam } from '@atlassian/jira-router';
import { useQueueCustomRouterContext } from '@atlassian/jira-servicedesk-common/src/navigation/queues/use-queue-custom-router-context';
import { useCategorizedNavItems } from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import {
	ExpandableMenuItem,
	ExpandableMenuItemContent,
	ExpandableMenuItemTrigger,
} from '@atlassian/navigation-system';
import { useSelectedNavLocation } from '../../../../controllers/nav-location';
import { QueueListSection } from '../../../../types';
import { AllQueues } from '../all-queues';
import { messages } from '../messages';

interface AllQueuesPopOutProps {
	projectKey: string;
	practice: ItsmPractice;
}

export const AllQueuesPopOut = ({ projectKey, practice }: AllQueuesPopOutProps) => {
	const { formatMessage } = useIntl();

	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const [allQueues] = useCategorizedNavItems('', projectKey, practice, queueCustomContext);
	const [queueIdFromPath] = usePathParam('queueId');
	const [selectedNavLocation] = useSelectedNavLocation(queueIdFromPath || '');

	if (!allQueues || allQueues.length === 0) {
		return null;
	}

	return (
		<ExpandableMenuItem
			isDefaultExpanded={selectedNavLocation.section === QueueListSection.ALL_QUEUES}
		>
			<ExpandableMenuItemTrigger>{formatMessage(messages.allQueues)}</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<AllQueues projectKey={projectKey} practice={practice} />
			</ExpandableMenuItemContent>
		</ExpandableMenuItem>
	);
};
