import React from 'react';
import range from 'lodash/fp/range';
import { SkeletonItem } from '@atlaskit/menu';
import { MenuListItem } from '@atlassian/navigation-system';
import type { SkeletonNavMenuItemsProps } from './types';

export const SkeletonNavMenuItems = ({ count = 5 }: SkeletonNavMenuItemsProps) =>
	range(0, count).map((index) => (
		<MenuListItem key={index}>
			<SkeletonItem isShimmering />
		</MenuListItem>
	));
