import { getIn, updateIn } from 'icepick';
import { useRouter } from '@atlassian/jira-router';
import type { CategorizedQueueData } from '@atlassian/jira-router-resources-service-desk-queues/src/types.tsx';
import { getQueueFragment } from '@atlassian/jira-servicedesk-common/src/navigation/queues';
import type { Page } from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import { toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { NavItem } from '../types';

type Data = CategorizedQueueData | null;

export const getQueuesForCategory = (data: Data, category: ItsmPractice): Page[] | undefined =>
	getIn(data, [category, 'queues']);

export const setQueuesForCategory = (
	data: Data,
	category: ItsmPractice,
	newQueues: Page[],
): CategorizedQueueData =>
	// @ts-expect-error(PARTIAL_RECORD) TS2740 - Type '{}' is missing the following properties from type 'CategorizedQueueData': "change-management", "incident-management", "problem-management", "service-request", and 2 more.
	updateIn(data || {}, [category, 'queues'], (queues) => {
		// We only want to update queues if they exist in a category already
		if (queues) {
			return newQueues;
		}

		throw new Error(`${category} does not exist in resource`);
	});

export const queuesToNavItems = (queues: Page[]): NavItem[] =>
	queues.map(
		({
			id,
			name,
			projectKey,
			count,
			category,
			favourite,
			canBeHidden,
			shouldSkipIssueCountRefresh,
			lastRefreshedTime,
		}: Page) => ({
			id: String(id),
			name,
			url: `/projects/${projectKey}/${getQueueFragment(id, category)}`,
			badgeCount: count,
			starred: favourite,
			canBeHidden,
			shouldSkipIssueCountRefresh,
			category,
			lastRefreshedTime,
		}),
	);

export const useProjectKey = () => {
	const [{ match }] = useRouter();
	return toProjectKey(match.params.projectKey || '');
};

export const useCurrentCategory = () => {
	const [{ match }] = useRouter();
	return toItsmPractice(match.params.practiceType);
};
