import { useEffect } from 'react';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { getNextgenSettingsUrl } from '@atlassian/jira-project-settings-apps-common/src/urls';
import { Events } from '@atlassian/servicedesk-frontend-routing';

// This event fires on navigation events in JSD's legacy SPA, BUT
// (unexpected) does not fire when navigating backwards and forward in the browser
// (expected) It does not fire when a page has a dirty form warning that delays navigation
const JSD_LEGACY_NAVIGATE_EVENT = 'jsdLegacyNavigate';

export const addNavigationListener = (
	projectKey: string,
	element: HTMLElement | null,
	onNavigation: (url: string) => void,
	isSettings: boolean,
) => {
	// Detach all event listeners for this event in case we already have one
	Events.off(JSD_LEGACY_NAVIGATE_EVENT);
	Events.on(JSD_LEGACY_NAVIGATE_EVENT, (fragment: string) => {
		if (isSettings) {
			return onNavigation(`${getNextgenSettingsUrl(SERVICE_DESK_PROJECT, projectKey)}/${fragment}`);
		}
		return onNavigation(`/jira/servicedesk/projects/${projectKey}/${fragment}`);
	});
};

export const useJsdLegacyNavigationListener = (onNavigation: (urlFragment: string) => void) => {
	useEffect(() => {
		Events.on(JSD_LEGACY_NAVIGATE_EVENT, onNavigation);
		return () => {
			Events.off(JSD_LEGACY_NAVIGATE_EVENT, onNavigation);
		};
	}, [onNavigation]);
};
