import { connect } from '../../../../../common/table-redux';
import { setVertical as setVerticalScrollOffset } from '../../../../../ops/scrolling/offset/action.tsx';
import { getFacade, getVisibleColumnCount } from '../../../../../state/consumer/selectors';
import { getDefaultRowHeight } from '../../../../../state/consumer/selectors/rows/index.tsx';
import { getVerticalScrollOffset } from '../../../../../state/internal/selectors.tsx';
import {
	hasHorizontalScrollbar,
	getShouldVirtualize,
	getVirtualBoundaries,
	getTableContentHeight,
} from '../../../../../state/selectors';
import type { State } from '../../../../../state/types';
import VerticalScrollbar from './index-dumb';

export default connect(
	(state: State) => ({
		visibleColumnCount: getVisibleColumnCount(state),
		facade: getFacade(state),
		isVirtualised: getShouldVirtualize(state),
		virtualBoundaries: getVirtualBoundaries(state),
		viewportHeight: getTableContentHeight(state),
		scrollTop: getVerticalScrollOffset(state),
		defaultRowHeight: getDefaultRowHeight(state),
		isHorizontalScrollVisible: hasHorizontalScrollbar(state),
	}),
	{ setVerticalScrollOffset },
)(VerticalScrollbar);
