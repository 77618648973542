import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-servicedesk-queues-common/src/log';
import {
	getBaseUrl,
	getProjectKey,
} from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props';
import add from '../../../rest/queues-history/index.tsx';
import { LOAD_PAGE_DATA } from '../../../state/actions/page';
import { QUEUE_CHANGED } from '../../../state/actions/queue';
import type { Action } from '../../../state/actions/types';
import type { State } from '../../../state/reducers/types';
import { isQueueTransient } from '../../../state/selectors/queue';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(LOAD_PAGE_DATA, QUEUE_CHANGED).switchMap((action) => {
		const { payload, type } = action;
		const queueId = type === LOAD_PAGE_DATA ? payload.queueId : payload.queue.id;
		const state = store.getState();
		const baseUrl = getBaseUrl(state);
		const projectKey = getProjectKey(state);
		if (isQueueTransient(state)) {
			return Observable.empty<never>();
		}

		// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
		return add(baseUrl, projectKey, queueId)
			.switchMap(() => Observable.empty<never>())
			.catch((error) => {
				log.safeWarnWithoutCustomerData(
					'servicedesk.queues.agent-view.ops.recent.add',
					`Failed to add recent queue - error: ${JSON.stringify(error)}`,
					{
						queueId,
					},
				);
				return Observable.empty<never>();
			});
	});
