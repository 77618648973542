import { connect } from '../../common/table-redux';
import { getDetailsPanelComponent } from '../../state/consumer/selectors/index.tsx';
import { getActiveRowId } from '../../state/selectors/index.tsx';
import type { State } from '../../state/types';
import DetailsPanel from './index-dumb';

export default connect(
	(state: State) => ({
		activeRowId: getActiveRowId(state),
		DetailsContent: getDetailsPanelComponent(state),
	}),
	{},
)(DetailsPanel);
