import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import {
	type QueueId,
	type Column,
	toQueueId,
	type QueueCategory,
} from '@atlassian/jira-servicedesk-queues-common/src/model';
import { getIsAdmin } from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/app-props';
import { NO_CATEGORY } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import type { SortedQueue } from '../../../model';
import type { QueueState, ColumnsById } from '../../reducers/persisted/queue/types';
import type { State } from '../../reducers/types';
import { getPersisted } from '../common';
import { getCurrentQueue } from '../ui';

export const getQueuePageData = (state: State): QueueState => getPersisted(state).queue;

export const getCategory = (state: State): QueueCategory => getQueuePageData(state).category;

export const getMetricKey = createSelector(getCategory, (category) =>
	category && category !== NO_CATEGORY
		? `servicedesk.queues.${category}.view`
		: 'servicedesk.queues.view',
);

export const getQueueId = (state: State): QueueId => {
	const queue = getCurrentQueue(state);
	return queue ? queue.id : toQueueId(-1);
};

export const getQueueName = (state: State): string => {
	const queue = getCurrentQueue(state);
	return queue ? queue.name : '';
};

export const getJql = (state: State): string => {
	const queue = getCurrentQueue(state);
	return queue ? queue.jql : '';
};

export const getCompleteJql = (state: State): string => {
	const queue = getCurrentQueue(state);
	return queue ? queue.completeJql : '';
};

export const isQueueTransient = (state: State): boolean => {
	const queue = getCurrentQueue(state);
	return !!(queue && queue.isTransient);
};

export const canManageQueue = (state: State): boolean =>
	getIsAdmin(state) && !isQueueTransient(state);

export const getColumns = (state: State): Column[] => {
	const queue = getCurrentQueue(state);
	return queue ? queue.columns : [];
};

export const getColumnIds = createSelector(getColumns, (columns) =>
	columns.map((column) => column.id),
);

export const getColumnsById = createSelector(getColumns, (columns) => keyBy(columns, 'id'));

const getColumnById = (state: State, columnId: string): Column | undefined =>
	getColumnsById(state)[columnId];

export const getColumnType = (state: State, columnId: string): string | undefined => {
	const column = getColumnById(state, columnId);
	return column && column.fieldType;
};

export const getColumnsInOrder = createSelector(
	getColumnIds,
	getColumnsById,
	(columnIds: string[], columnsById: ColumnsById) => {
		// To prevent scenario where columnIds exist but columnsById do not
		if (columnIds.length === Object.keys(columnsById).length) {
			const checkbox = {
				id: 'checkbox',
				title: '',
				fieldType: 'checkbox',
				sortable: false,
			};

			return [checkbox].concat(columnIds.map((colId) => columnsById[colId]));
		}
		return [];
	},
);

export const getNumberOfColumns = (state: State): number => getColumns(state).length;

export const getAllFieldTypes = (state: State): string[] =>
	getColumns(state).map((column) => column.fieldType);

export const getFetchStartIndex = (state: State): number => getQueuePageData(state).fetchStartIndex;

export const getCurrentQueueOrder = (state: State): number => {
	const current = getCurrentQueue(state);
	return current ? current.order : -1;
};

// Differing id, jql and columns between the persisted and passed queue are
// considered "materially different": our issue state will be invalid if
// changed to this new queue.
export const isQueueMateriallyDifferent = (state: State, otherQueue: SortedQueue): boolean =>
	otherQueue.id !== getQueueId(state) ||
	otherQueue.jql !== getJql(state) ||
	otherQueue.columns !== getColumns(state) ||
	otherQueue.category !== getCategory(state);
