import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { PACKAGE_NAME } from './common/constants';
import { queuesAutomationMenuEntryPoint } from './entrypoint';

const runtimeProps = {};
const entryPointParams = {};
export const AsyncQueuesAutomationMenu = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		queuesAutomationMenuEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="jsm.queues.automation.menu"
			packageName={PACKAGE_NAME}
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={runtimeProps}
			errorFallback={
				ff('provide-fallbacks-for-async-bundles-failed-to-load_v30w1') ? 'flag' : undefined
			}
		/>
	);
};
