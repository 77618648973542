import React, { useContext } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import FavoriteButtonStateless from '@atlassian/jira-favourite-button-stateless/src/view/view.tsx';
import { QUEUES_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider/src/model/constants.tsx';
import { FavoriteChangeContext } from '@atlassian/jira-favourite-change-provider/src/view/index.tsx';
import type { StarredButtonProps } from './types';

export const StarButton = ({ isStarred, queueId }: StarredButtonProps) => {
	const { changeFavorite } = useContext(FavoriteChangeContext);

	return (
		<FavoriteButtonStateless
			isFavorite={isStarred}
			pending={false}
			isSmall
			onClick={(analyticsEvent: UIAnalyticsEvent) => {
				changeFavorite(
					{
						id: queueId,
						value: !isStarred,
						type: QUEUES_ITEM_TYPE,
					},
					analyticsEvent,
				);
			}}
		/>
	);
};
