import { useState, useEffect } from 'react';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIsDemoProject } from '@atlassian/jira-servicedesk-itsm-sample-project/src/services/is-demo-project/index.tsx';
import { useIsItsmSampleProject } from '@atlassian/jira-servicedesk-itsm-sample-project/src/services/is-itsm-sample-project/index.tsx';
import createNextRequestService from '../services/create-next-request';
import type { Props } from './types';

export const DemoProjectDripFeed = ({ baseUrl, projectId }: Props) => {
	const { data: isDemoProject } = useIsDemoProject();
	const { data: isItsmSampleProject } = useIsItsmSampleProject();
	const [isRequestCreated, setIsRequestCreated] = useState(false);
	const shouldCreateRequest =
		// Allow request creation for demo and non ITSM sample project
		!isRequestCreated && isDemoProject === true && isItsmSampleProject === false;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (shouldCreateRequest) {
			setIsRequestCreated(true);
			createNextRequestService({
				baseUrl,
				projectId,
				analyticsEvent: createAnalyticsEvent({}),
			});
		}
	}, [shouldCreateRequest, setIsRequestCreated, baseUrl, projectId, createAnalyticsEvent]);

	return null;
};
