import { getIn } from 'icepick';
import { createSelector } from 'reselect';
import countBy from 'lodash/countBy';
import log from '@atlassian/jira-common-util-logging/src/log';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import type {
	FieldDataSelectorChildrenProps,
	FieldDataSelectorCoordinates,
} from '@atlassian/jira-issue-table/src/model';
import { getEcClient } from '@atlassian/jira-jsis-ec-client/src/services/index.tsx';
import IssueMutation from '@atlassian/jira-jsis-ec-client/src/services/issue-mutation/index.tsx';
import { MutationSource } from '@atlassian/jira-jsis-ec-client/src/services/storage/constants.tsx';
import type { IndexItems } from '@atlassian/jira-servicedesk-queues-common/src/model';
import type { ChangeIssueFieldActionDispatcher } from '@atlassian/jira-servicedesk-queues-common/src/state/actions/issue/field';
import { transformField } from '@atlassian/jira-servicedesk-queues-common/src/state/selectors/issues/field-transformers';
import type { CollectionItem } from '../../../model';
import type { Issues } from '../../reducers/persisted/issues/types';
import type { State } from '../../reducers/types';
import { getPersisted } from '../common';
import { getColumnIds, getColumnType } from '../queue';

const isEcClientEnabled = (): boolean => {
	const experiences: string[] =
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(getFeatureFlagValue('hela.ec.client.integration.jsm', '') as string).split(',');

	return Array.isArray(experiences) && experiences.includes('jsm-inline');
};

const STATUS_COLUMN = 'status';
const ASSIGNEE_COLUMN = 'assignee';

const ROWS_TO_MOUNT_EXPERIENCE_TRACKERS = 50;
const FIELD_TYPES_TO_MOUNT_EXPERIENCE_TRACKERS: Set<string> = new Set([
	'status',
	'summary',
	'issuetype',
	'issuekey',
]);

const getIssues = (state: State): Issues => getPersisted(state).issues;

// Total count of all issues in queue
export const getIssueCount = (state: State): number => getIssues(state).totalCount;

export const getCollection = (state: State): CollectionItem[] => getIssues(state).collection;

const getIssueKeys = createSelector(getCollection, (collection) => {
	const issueKeys: Array<string> = [];
	collection.forEach((issue, idx) => {
		if (issue) {
			issueKeys[idx] = issue.key;
		}
	});
	return issueKeys;
});

const getIndex = (state: State): IndexItems => getIssues(state).index;

export const getTimeWhenLastInitialized = (state: State): number =>
	getIssues(state).timeWhenLastInitialized;

export const getIssueCacheSize = createSelector(getIndex, (index) => Object.keys(index).length);

const doesFirstMatch = (array: string[], expectedFirst: string) =>
	array.length > 0 && array[0] === expectedFirst;

export const isFirstFieldInTable = (state: State, issueKey: string, fieldId: string): boolean => {
	const issues = getIssueKeys(state);
	const columns = getColumnIds(state);
	return doesFirstMatch(issues, issueKey) && doesFirstMatch(columns, fieldId);
};

export const isFieldToExperienceTrack = (
	state: State,
	issueKey: string,
	fieldId: string,
): boolean => {
	const positionIndex = getIndex(state)[issueKey];
	const columnType = getColumnType(state, fieldId);

	const isInFirstRows = positionIndex != null && positionIndex < ROWS_TO_MOUNT_EXPERIENCE_TRACKERS;
	const isExperienceTrackingFieldType =
		columnType != null && FIELD_TYPES_TO_MOUNT_EXPERIENCE_TRACKERS.has(columnType);

	return isInFirstRows && isExperienceTrackingFieldType;
};

export const getFieldPropsSelector = createSelector(
	getIssues,
	(issues: Issues) =>
		(
			{ issueKey, fieldDescriptor: { fieldId, fieldType } }: FieldDataSelectorCoordinates,
			issueFieldDispatcher: ChangeIssueFieldActionDispatcher,
		): FieldDataSelectorChildrenProps => {
			const indexOfIssue = issues.index[issueKey];

			if (indexOfIssue >= 0) {
				const issue = issues.collection[indexOfIssue];
				if (issue && issue.key === issueKey) {
					const storedFieldValue = issue.fields[fieldId];
					const issueID = issue.id;
					if (storedFieldValue != null) {
						// @ts-expect-error - TS7006 - Parameter 'newVal' implicitly has an 'any' type.
						const onFieldCellChange = (newVal) => {
							issueFieldDispatcher(issueKey, fieldId, newVal);
							try {
								if (isEcClientEnabled()) {
									const jsisECClient = getEcClient();
									const mutationObj = new IssueMutation(
										issueID.toString(),
										MutationSource.CREATE,
										'1',
										[],
									);
									const analyticsEventObj = {
										analyticsMetadata: { scenario: 'jsm-inline' },
									};

									jsisECClient.saveIssueMutationToCache(mutationObj, analyticsEventObj, {
										errorMsg: 'FAILED_JSM_INLINE',
										error: true,
									});
								}
							} catch (e) {
								log.safeErrorWithoutCustomerData(
									'jsis-ec-client',
									`jsm-inline-err : ${e?.toString() || ''}`,
								);
							}
						};
						return transformField(issue, storedFieldValue, fieldType, onFieldCellChange);
					}
				}
			}
			return null;
		},
);

export const getIssueStatusIdsCounts = createSelector(getCollection, (collection) =>
	countBy(
		collection
			.map((issue) => getIn(issue, ['fields', STATUS_COLUMN, 'value', 'id']))
			.filter((id) => id),
	),
);

const getIssueCountsByAssignee = createSelector(getCollection, (collection) =>
	countBy(
		collection
			.map((issue) => getIn(issue, ['fields', ASSIGNEE_COLUMN, 'value', 'accountId']))
			.filter((id) => id),
	),
);

export const getIssueCountByAssignee = (state: State, assigneeId?: null | string) =>
	assigneeId ? getIssueCountsByAssignee(state)[assigneeId] || 0 : 0;
