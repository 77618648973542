import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales';
import calendarLocaleModule from '@atlassian/jira-wrm/src/common-modules/calendar-locale-module.tsx';

/*
 * == Why does calendar-resource-includer.js exist? ==
 *
 * -- Short version --
 * The Automation page, deep down, uses Calendar.js library. The library consists of three files [1].
 * There is a file that maps this dependency [2] but this file is incomplete - it only has two files out of three.
 * calendar-resource-includer comes to rescue, and imports the third file so that calendar works again.
 *
 * -- Long version --
 * Automation page has many triggers and rules and blueprints. Some of them include classic forms and fields.
 * These fields may be of a Date type. The calendar is split to three files:
 * [1] https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-webapp-common/src/main/webapp/includes/lib/calendar/Calendar.js
 * [1] https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-webapp-common/src/main/webapp/includes/lib/calendar/Calendar.setup.js
 * [1] https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-webapp-common/src/main/webapp/includes/lib/calendar/lang/calendar-sk.js
 *
 * Two of them are included in system-webresources-plugin.xml:
 * [2] https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-core/src/main/resources/system-webresources-plugin.xml#3404
 *
 * But! That .xml is missing the third locale file.
 *
 * In classic project, this works because there is ProjectSettingsApplicationAction.java that includes it:
 * https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-plugins/jira-servicedesk/sd-components/project-ui/src/main/java/com/atlassian/servicedesk/project/internal/web/action/ProjectSettingsApplicationAction.java#143
 *
 * But ProjectSettingsApplicationAction.java is not called in next-gen projects, so we have to do that here.
 *
 * The alternative is to add this code on backend, in PanelServlet.java:
 * https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-plugins/jira-project-config-plugin/src/main/java/com/atlassian/jira/projectconfig/servlet/PanelServlet.java#502
 * but we prefer to do this work on frontend because.
 *
 * The chain of dependencies starts in PanelServlet.java and goes like this:
 *
 * - com.atlassian.jira.jira-project-config-plugin:project-config-global
 *   -> com.atlassian.jira.jira-project-config-plugin:project-config-global-resources
 *     -> jira.webresources:jira-fields
 *       -> jira.webresources:calendar
 *         -> (here should be locale file but is not!)
 *
 * The error thrown when this is not included is "Uncaught TypeError: Cannot read property 'DEF_DATE_FORMAT' of undefined"
 *
 * We want to avoid doing this in the long run. Before adding more WRM resources, consider alternatives first!
 * TODO remove the calendar and calendar-resource-includer: https://jdog.jira-dev.com/browse/JDW-529
 */

export const getCalendarResources = (locale: Locale) => calendarLocaleModule(locale);
