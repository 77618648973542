import { useEffect, useState } from 'react';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { getProjectCreationUTCDate } from './get-project-creation-utc-date';

export const useIsJSMImporterSurveyEnabled = () => {
	const [showJSMImporterSurvey, setShowJSMImporterSurvey] = useState(false);
	const isSiteAdmin = useIsSiteAdmin();
	const { data } = useProjectContext() || {};
	const { projectKey } = data || {};

	useEffect(() => {
		const getJSMImporterSurveyEnabled = async () => {
			if (expValEquals<string>('jsm_jira_importer_learning_experiment', 'cohort', 'variation')) {
				setShowJSMImporterSurvey(true);
				return;
			}

			const projectCreationUTCDate = await getProjectCreationUTCDate({
				projectKey: projectKey || '',
			});

			if (!projectCreationUTCDate) {
				return;
			}

			const experimentStartDate = new Date('2024-06-17T06:30:00Z');
			const experimentStopDate = new Date('2024-07-08T06:30:00Z');
			const projectCreationDate = new Date(projectCreationUTCDate);

			if (
				projectCreationDate > experimentStartDate &&
				projectCreationDate < experimentStopDate &&
				expValEquals<string>('jsm_importer_learning_experiment_for_existing', 'cohort', 'variation')
			) {
				setShowJSMImporterSurvey(true);
			}
		};
		if (isSiteAdmin) {
			getJSMImporterSurveyEnabled();
		}
	}, [isSiteAdmin, projectKey]);

	return { showJSMImporterSurvey };
};
