import { useEffect } from 'react';
import { useResource } from '@atlassian/jira-router';
import { kbConfluenceContextResource } from '@atlassian/jira-router-resources-confluence-context/src/services/index.tsx';

const getKbConfluenceContextResource = () => kbConfluenceContextResource;

export const useKbConfluenceContext = (projectKey?: string) => {
	const {
		data,
		loading,
		error,
		refresh: refreshCache,
	} = useResource(getKbConfluenceContextResource());

	useEffect(() => {
		if (!__SERVER__) {
			refreshCache();
		}
	}, [projectKey, refreshCache]);

	return [{ data, loading, error }] as const;
};

export default useKbConfluenceContext;
