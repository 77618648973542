import React from 'react';
import AlignLeftIcon from '@atlaskit/icon/core/align-left';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useQueueCustomRouterContext } from '@atlassian/jira-servicedesk-common/src/navigation/queues/use-queue-custom-router-context';
import { useCategorizedNavItems } from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import {
	FlyoutMenuItem,
	FlyoutMenuItemTrigger,
	FlyoutMenuItemContent,
} from '@atlassian/navigation-system';
import { AllQueues } from '../all-queues';
import { messages } from '../messages';

interface AllQueuesFlyoutProps {
	projectKey: string;
	practice: ItsmPractice;
}

export const AllQueuesFlyout = ({ projectKey, practice }: AllQueuesFlyoutProps) => {
	const { formatMessage } = useIntl();
	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const [allQueues] = useCategorizedNavItems('', projectKey, practice, queueCustomContext);
	if (!allQueues || allQueues.length === 0) {
		return null;
	}

	return (
		<FlyoutMenuItem>
			<FlyoutMenuItemTrigger
				iconBefore={<AlignLeftIcon label="" spacing="spacious" color={token('color.icon')} />}
			>
				{formatMessage(messages.viewAllQueues)}
			</FlyoutMenuItemTrigger>
			<FlyoutMenuItemContent>
				<AllQueues projectKey={projectKey} practice={practice} isFlyOut />
			</FlyoutMenuItemContent>
		</FlyoutMenuItem>
	);
};
