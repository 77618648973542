import React, {
	useEffect,
	useState,
	useMemo,
	useCallback,
	useRef,
	type ComponentType,
	type KeyboardEvent,
	Component,
	type PropsWithChildren,
} from 'react';
import { styled } from '@compiled/react';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import keycode from 'keycode';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import DetailViewIcon from '@atlaskit/icon/glyph/detail-view';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { WidthObserver } from '@atlaskit/width-detector';
import { SERVICEDESK_QUEUES_ISSUE } from '@atlassian/jira-common-constants/src/analytics-sources';
import { PAGE_LAYOUT_OFFSET_TOP } from '@atlassian/jira-common-constants/src/page-layout';
import { ff, getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { SelectedIssuesContainer } from '@atlassian/jira-issue-table-selection-services/src/services/index.tsx';
import {
	ChangeEventTypes,
	type ChangeEvent,
} from '@atlassian/jira-issue-view-model/src/change-type';
import { useEcClient } from '@atlassian/jira-jsis-ec-client/src/services/ec-components/index.tsx';
import IssueMutation from '@atlassian/jira-jsis-ec-client/src/services/issue-mutation/index.tsx';
import { MutationSource } from '@atlassian/jira-jsis-ec-client/src/services/storage/constants.tsx';
import { JsmRepackagingBanner } from '@atlassian/jira-jsm-repackaging-banner/src/async.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout/src/common/utils/get-will-show-nav4/index.tsx';
import type { Actions as NavUIEventListenerActions } from '@atlassian/jira-navigation-ui-controller/src/types';
import {
	ContextualAnalyticsData,
	FireTrackAnalytics,
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { createResizeObserverHook } from '@atlassian/jira-react-use-resize-observer';
import { LoadedIssuesContainer } from '@atlassian/jira-servicedesk-bulk-actions-toolbar/src/services/smart-suggestions';
import { saveIssueMutationAnalyticsToCache } from '@atlassian/jira-servicedesk-common/src/issue-change';
import PageSpinner from '@atlassian/jira-servicedesk-common/src/ui/components/page-spinner';
import { AsyncPostIncidentReviewBenefitModal } from '@atlassian/jira-servicedesk-post-incident-review-tour/src/async';
import CategorizedActionsProvider from '@atlassian/jira-servicedesk-queues-categorized-store/src/services/actions-provider/main.tsx';
import {
	type Column,
	fromQueueIdString,
	type QueueCategory,
} from '@atlassian/jira-servicedesk-queues-common/src/model';
import { createNavigationApi } from '@atlassian/jira-servicedesk-queues-common/src/navigation/api';
import QueuesPopoutPanelNav4 from '@atlassian/jira-servicedesk-queues-popout-panel-nav4/src/ui';
import {
	PROBLEMS,
	INCIDENTS,
	NO_CATEGORY,
	POST_INCIDENT_REVIEWS,
	CHANGES,
} from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { FFErrorBoundary } from '../../common/ff-error-boundary';
import type { SortedQueue, CollectionItem, OnAtLeastOneIssueLoadedCallback } from '../../model';
import { BoardPromoSpotlight } from './board-promo-spotlight';
import { FilterQueryFailed } from './filter-query-failed';
import type Header from './header';
import messages from './messages';
import QueuesDetails from './queues-details';
import QueuesHeader from './queues-header';
import { SidebarIssueView } from './sidebar-issue-app';
import type { Props as TableProps } from './table/view';

const isEcClientEnabled = () => {
	const experiences: string[] = getFeatureFlagValue<string>(
		'hela.ec.client.integration.jsm',
		'',
	).split(',');
	return Array.isArray(experiences) && experiences.includes('jsm-issue-view');
};

const JsmRepackagingBannerWrapper = ({ children }: PropsWithChildren) => (
	<Box xcss={jsmRepackagingBannerStyles}>{children}</Box>
);

export interface SidebarApi {
	isCollapsed: boolean;
	navActions: NavUIEventListenerActions;
}

export interface OwnProps {
	isQueueVisible: boolean;
	queue: SortedQueue;
	onAtLeastOneIssueLoaded: OnAtLeastOneIssueLoadedCallback;
	onIssuesChanged?: (keys: (string | undefined)[]) => void;
	onIssuesCountChanged?: (count: number) => void;
}

export interface StateProps {
	isInitialized: boolean;
	issueCount: number;
	issueCollection: CollectionItem[];
	isQueueEmpty: boolean;
	isLoadingIssues: boolean;
	isCurrentDataFiltered: boolean;
	showIssueFailureError: boolean;
	projectId: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ErrorState: ComponentType<any>;
	Header: typeof Header;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	EmptyQueue: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	UsageSampler: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	NavUpdater: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ViewTracker: ComponentType<any>;
	Table: ComponentType<Partial<TableProps>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	QueuesBreadcrumb: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	IssueContextListener: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ItsmContextListener: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	EmptyFilteredQueue: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	UpFlowPersistentUpgradeBanner: ComponentType<any>;
	baseUrl: string;
	projectKey: string;
	columns: Column[];
	jql: string;
	itsmPractices: string[];
	category: QueueCategory;
	onboardingResolved: boolean;
	shouldShowBadFilterQueryError: boolean;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	sidebarIssueKey: string | null;
	sidebarReactKey: string | null;
	isQueuesErrorHeaderDisplayed: boolean;
}

export interface DispatchProps {
	onOnboardingResolved: (arg1: boolean) => void;
	onQueueChanged: (arg1: SortedQueue) => void;
	onUnmount: () => void;
	onPageVisibilityChanged: (arg1: boolean) => void;
	onNavCollapseChange: (arg1: boolean) => void;
	onWindowFocus: () => void;
	onFilterClear: () => void;
	onIssueChange: () => void;
	onSetIssueKey: (key: string | null) => void;
	onNonPremiumSLAColumnError: () => void;
}

export type Props = StateProps & DispatchProps & OwnProps;

type MergedProps = Props & { sidebarApi: SidebarApi | undefined };

type State = {
	startTime: number | null;
};

const SidebarIcon = (props: { onClickHandler: () => void }) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnKeyDown = (event: KeyboardEvent<HTMLElement>) => {
		if (event.keyCode === keycode('enter')) {
			event.stopPropagation();
		}
	};

	const onTooltipShow = () => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'viewed',
			actionSubject: 'queueSideBarIcon',
		});
		fireUIAnalytics(analyticsEvent, 'sideBarIconShown');
	};

	// TODO This should flip between sidebar/dialog message when that is implemented
	return (
		<Tooltip content={formatMessage(messages.openIssueInSidebar)} onShow={onTooltipShow}>
			<Button
				aria-label={formatMessage(messages.openIssueInSidebar)}
				// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
				iconBefore={<DetailViewIcon label={formatMessage(messages.openIssueInSidebar)} />}
				appearance="subtle"
				spacing="compact"
				onClick={props.onClickHandler}
				onKeyDown={handleOnKeyDown}
			/>
		</Tooltip>
	);
};

const WINDOW_ACTIVE_EVENT = 'visibilitychange';
// eslint-disable-next-line jira/react/no-class-components
export default class AgentView extends Component<MergedProps, State> {
	constructor(props: MergedProps) {
		super(props);
		this.state = {
			startTime: null,
		};
	}

	componentDidMount() {
		const { queue, issueCollection, issueCount } = this.props;
		this.prevQueue = queue;
		this.addNavigationEventListeners();
		document.addEventListener(WINDOW_ACTIVE_EVENT, this.props.onWindowFocus);
		this.triggerIssuesChange(issueCollection);
		this.triggerIssuesCountChange(issueCount);
		this.setStartTime();
	}

	componentDidUpdate(prevProps: Props) {
		const {
			onboardingResolved,
			onOnboardingResolved,
			issueCount,
			onAtLeastOneIssueLoaded,
			onWindowFocus,
			isQueueVisible,
			onPageVisibilityChanged,
			queue,
			issueCollection,
		} = this.props;
		// onAtLeastOneIssueLoaded() lets the monolith know that a issue has loaded (for onboarding purposes)
		if (!onboardingResolved && issueCount > 0) {
			onAtLeastOneIssueLoaded();
			onOnboardingResolved(true);
		}
		if (prevProps.onWindowFocus !== onWindowFocus) {
			document.removeEventListener(WINDOW_ACTIVE_EVENT, prevProps.onWindowFocus);
			document.addEventListener(WINDOW_ACTIVE_EVENT, onWindowFocus);
		}

		if (prevProps.isQueueVisible !== isQueueVisible) {
			onPageVisibilityChanged(isQueueVisible);
		}

		prevProps.issueCollection !== issueCollection && this.triggerIssuesChange(issueCollection);
		prevProps.issueCount !== issueCount && this.triggerIssuesCountChange(issueCount);

		this.prevQueue = queue;
	}

	componentWillUnmount() {
		this.removeNavigationEventListeners();
		this.props.onUnmount();
		document.removeEventListener(WINDOW_ACTIVE_EVENT, this.props.onWindowFocus);
	}

	setStartTime() {
		const { startTime } = this.state;
		if (!startTime) {
			this.setState({ startTime: Date.now() });
		}
	}

	getIsLoadingState(): boolean {
		const { isLoadingIssues, queue } = this.props;
		const { prevQueue } = this;

		if (prevQueue && prevQueue.id !== queue.id) {
			return true;
		}

		return isLoadingIssues;
	}

	isLoadingQueues(): boolean {
		const { queue } = this.props;
		const { prevQueue } = this;

		return prevQueue && prevQueue.id !== queue.id;
	}

	addNavigationEventListeners() {
		const { onNavCollapseChange, sidebarApi } = this.props;
		if (sidebarApi) {
			const { isCollapsed, navActions } = sidebarApi;
			const { collapseStartAdd, collapseStartClear, expandStartAdd, expandStartClear } = navActions;

			onNavCollapseChange(isCollapsed);
			collapseStartAdd(() => onNavCollapseChange(true));
			expandStartAdd(() => onNavCollapseChange(false));

			this.removeNavListeners = () => {
				collapseStartClear();
				expandStartClear();
			};
		} else {
			const navigationApi = createNavigationApi();
			navigationApi
				.then((sidebar) => {
					onNavCollapseChange(sidebar.isCollapsed());
					const removeCollapseListener = sidebar.onCollapseStart(() => onNavCollapseChange(true));
					const removeExpandListener = sidebar.onExpandStart(() => onNavCollapseChange(false));
					this.removeNavListeners = () => {
						removeCollapseListener();
						removeExpandListener();
					};
				}) // Replace with lodash/noop
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				.catch(() => {});
		}
	}

	removeNavigationEventListeners() {
		this.removeNavListeners && this.removeNavListeners();
	}

	triggerQueueChange() {
		const { queue, onQueueChanged } = this.props;

		if (
			!isEqual(this.prevQueue?.id, queue.id) ||
			!isEqual(this.prevQueue?.completeJql, queue.completeJql) ||
			!isEqual(this.prevQueue?.columns, queue.columns) ||
			!isEqual(this.prevQueue?.isFavorite, queue.isFavorite)
		) {
			onQueueChanged(queue);
		}
	}

	triggerIssuesChange(issueCollection: CollectionItem[]) {
		const { onIssuesChanged } = this.props;
		onIssuesChanged && onIssuesChanged(issueCollection.map((issue) => issue?.key));
	}

	triggerIssuesCountChange(issuesCount: number) {
		const { onIssuesCountChanged } = this.props;
		onIssuesCountChanged?.(issuesCount);
	}

	// @ts-expect-error - TS2564 - Property 'prevQueue' has no initializer and is not definitely assigned in the constructor.
	prevQueue: SortedQueue;

	removeNavListeners: (() => void) | undefined;

	renderView() {
		const {
			Table,
			EmptyQueue,
			EmptyFilteredQueue,
			isQueueEmpty,
			isCurrentDataFiltered,
			shouldShowBadFilterQueryError,
			onFilterClear,
			onIssueChange,
			onSetIssueKey,
			onNonPremiumSLAColumnError,
			columns,
			sidebarIssueKey,
			sidebarReactKey,
		} = this.props;

		if (this.isLoadingQueues()) {
			// errorBoundary not necessary since core functionality is not visible
			return null;
		}

		if (isCurrentDataFiltered && !this.getIsLoadingState() && shouldShowBadFilterQueryError) {
			return (
				<EmptyStateWrapper>
					<FireTrackAnalytics eventName="jsmQueueFiltering failed" />
					<EmptyWrapper>
						<FilterQueryFailed onFilterClear={onFilterClear} />
					</EmptyWrapper>
					<Table />
				</EmptyStateWrapper>
			);
		}

		if (isCurrentDataFiltered && isQueueEmpty) {
			return (
				<EmptyStateWrapper>
					<EmptyWrapper>
						<EmptyFilteredQueue />
					</EmptyWrapper>
					<Table />
				</EmptyStateWrapper>
			);
		}

		if (isQueueEmpty) {
			return <EmptyQueue />;
		}

		return (
			<TableWithIssueSidebar
				Table={Table}
				onIssueChange={onIssueChange}
				onSetIssueKey={onSetIssueKey}
				columns={columns}
				issueKey={sidebarIssueKey}
				reactKey={sidebarReactKey}
				onError={onNonPremiumSLAColumnError}
			/>
		);
	}

	renderWithoutSelectedIssuesContainer() {
		const {
			isInitialized,
			ErrorState,
			UsageSampler,
			Header,
			queue: { id: queueId },
			NavUpdater,
			showIssueFailureError,
			isQueueVisible,
			ViewTracker,
			QueuesBreadcrumb,
			IssueContextListener,
			ItsmContextListener,
			UpFlowPersistentUpgradeBanner,
			baseUrl,
			projectKey,
			category,
			onNonPremiumSLAColumnError,
		} = this.props;

		const isLoading = this.getIsLoadingState();
		// This is a state change during render, which is bad. See the comment
		// around ServiceDeskAnalyticsContext#forceRenderWhileLoading:
		// https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/jira/src/packages/servicedesk/queues-agent-view/src/view/index.tsx#110
		this.triggerQueueChange();

		if (!isInitialized) {
			return <PageSpinner />;
		}

		if (showIssueFailureError) {
			return (
				<>
					{fg('jsm-fission-decompose-queue-page-header') ? (
						<CategorizedActionsProvider
							baseUrl={baseUrl}
							projectKey={projectKey}
							category={category || NO_CATEGORY}
						>
							{(actions) => (
								<>
									{getWillShowNav4() &&
									ff('jsm-queues-nav4-refresh-m2-queues-pop-out-view_atq13') ? (
										<>
											<QueuesHeader onDeleteComplete={actions.deleteItem} />
											<QueuesPopoutPanelNav4>
												<QueuesDetails />
												<ErrorState />
											</QueuesPopoutPanelNav4>
										</>
									) : (
										<>
											<QueuesHeader onDeleteComplete={actions.deleteItem} />
											<QueuesDetails />
											<ErrorState />
										</>
									)}
								</>
							)}
						</CategorizedActionsProvider>
					) : (
						<CategorizedActionsProvider
							baseUrl={baseUrl}
							projectKey={projectKey}
							category={category || NO_CATEGORY}
						>
							{(actions) => (
								<>
									<Header queueId={queueId} onDeleteComplete={actions.deleteItem} />
									<ErrorState />
								</>
							)}
						</CategorizedActionsProvider>
					)}
				</>
			);
		}

		return (
			<QueuesBreadcrumb>
				<IssueContextListener />
				<ItsmContextListener projectKey={projectKey} />
				{isQueueVisible && <UpFlowPersistentUpgradeBanner isBannerEnabled isFullWidth />}
				<LayoutContainer
					visible={isQueueVisible}
					// Do not remove this. Referred to in monolith - com/atlassian/servicedesk/agent/queues/QueuesPage.java
					data-test-id="servicedesk-queues-agent-view.layout.layout"
				>
					{isQueueVisible && <ViewTracker key={fromQueueIdString(queueId)} queueId={queueId} />}
					{(category === INCIDENTS || category === POST_INCIDENT_REVIEWS) && (
						<AsyncPostIncidentReviewBenefitModal queueCategory={category} />
					)}
					<CategorizedActionsProvider
						baseUrl={baseUrl}
						projectKey={projectKey}
						category={category || NO_CATEGORY}
					>
						{(actions) => (
							<>
								<FFErrorBoundary onError={onNonPremiumSLAColumnError} id="queue-nav-updater">
									{!isLoading && (
										<NavUpdater
											queueId={queueId}
											onQueueIssueCountUpdated={actions.editItemIssueCount}
											onProjectStateHashUpdate={actions.refreshNavItems}
										/>
									)}
									<UsageSampler />
								</FFErrorBoundary>
								{fg('jsm-fission-decompose-queue-page-header') ? (
									<QueuesHeader onDeleteComplete={actions.deleteItem} />
								) : (
									<Header queueId={queueId} onDeleteComplete={actions.deleteItem} />
								)}
							</>
						)}
					</CategorizedActionsProvider>

					{fg('jsm-fission-decompose-queue-page-header') ? (
						<>
							{ff('enable_jsm_repackaging_changes_vtwk9') &&
								(category === CHANGES || category === PROBLEMS) && (
									<JsmRepackagingBanner
										workCategory={category}
										renderWrapper={JsmRepackagingBannerWrapper}
									/>
								)}
							{getWillShowNav4() && ff('jsm-queues-nav4-refresh-m2-queues-pop-out-view_atq13') ? (
								<QueuesPopoutPanelNav4>
									<QueuesDetails />
									{this.renderView()}
								</QueuesPopoutPanelNav4>
							) : (
								<>
									<QueuesDetails />
									{this.renderView()}
								</>
							)}
						</>
					) : (
						<>
							{ff('enable_jsm_repackaging_changes_vtwk9') &&
								(category === CHANGES || category === PROBLEMS) && (
									<JsmRepackagingBanner
										workCategory={category}
										renderWrapper={JsmRepackagingBannerWrapper}
									/>
								)}
							{this.renderView()}
						</>
					)}
				</LayoutContainer>
			</QueuesBreadcrumb>
		);
	}

	render() {
		const {
			queue: { id: queueId },
			itsmPractices,
			issueCollection,
			issueCount,
			projectKey,
			projectId,
			columns,
			jql,
		} = this.props;
		return (
			<ContextualAnalyticsData attributes={{ itsmPractices }}>
				<SelectedIssuesContainer scope={`queue - ${fromQueueIdString(queueId)}`}>
					<LoadedIssuesContainer
						scope={`queue - ${fromQueueIdString(queueId)}`}
						queueId={fromQueueIdString(queueId)}
						projectKey={projectKey}
						projectId={projectId}
						issueCollection={issueCollection}
						totalIssueCount={issueCount}
						columns={columns}
						jql={jql}
					>
						{this.renderWithoutSelectedIssuesContainer()}
					</LoadedIssuesContainer>
				</SelectedIssuesContainer>
			</ContextualAnalyticsData>
		);
	}
}

const { useResizeObserver } = createResizeObserverHook({
	debouncePeriod: 50, // optional debouncing of resize notifications
});

interface TableWithIssueSidebarProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Table: ComponentType<any>;
	onIssueChange: () => void;
	onSetIssueKey: (key: string | null) => void;
	onError: () => void;
	columns: Column[];
	issueKey: string | null;
	reactKey: string | null;
}
const TableWithIssueSidebar = ({
	Table,
	onIssueChange,
	onSetIssueKey,
	onError,
	columns,
	issueKey,
	reactKey,
}: TableWithIssueSidebarProps) => {
	const [width, setWidth] = useState<number>(0);
	const [tableWidth, setTableWidth] = useState<number>(0);
	const [isResizing, setIsResizing] = useState<boolean>(false);
	const [startTime, setStartTime] = useState<number | null>(null);
	const throttledSetWidth = useMemo(() => throttle(setWidth, 50), []);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let saveIssueMutationToCache: any;
	if (isEcClientEnabled()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		saveIssueMutationToCache = useEcClient().saveIssueMutationToCache;
	}

	useEffect(() => {
		if (columns && !!columns.find((col) => col.id === 'summary')) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'queueSideBar enabled');
		}
	}, [columns, createAnalyticsEvent]);

	const ref = useRef<HTMLDivElement>(null);
	useResizeObserver({
		ref,
		onResize() {
			setTableWidth(ref.current?.clientWidth ?? 0);
		},
	});

	const onChangeHandler = (event: ChangeEvent) => {
		if (ff('jsm-saveissuemutationtocache-refactor_oyhki', false)) {
			onIssueChange();
			saveIssueMutationAnalyticsToCache(event, createAnalyticsEvent, saveIssueMutationToCache);
		} else {
			onChangeHandlerOld(event);
		}
	};

	const onChangeHandlerOld = (event: ChangeEvent) => {
		onIssueChange();
		if (isEcClientEnabled() && event?.type === ChangeEventTypes.FIELD_CHANGED) {
			const mutationFailedAnalyticsPayload = {
				errorMsg: 'FAILED_JSM_ISSUEVIEW',
				error: true,
			};
			const analyticsEventObj = createAnalyticsEvent({});
			const analyticsDataKey = {
				analyticsEventObj,
				analyticsMetadata: {
					scenario: 'jsm-issue-view',
					type: event.type,
				},
			};

			saveIssueMutationToCache(
				new IssueMutation(event?.issueId, MutationSource.UPDATE),
				analyticsDataKey,
				mutationFailedAnalyticsPayload,
			);
		}
	};

	const renderSidebarIcon = useCallback(
		(selectedIssueKey: string) => {
			const onClickHandler = () => {
				const sidebarIconClickEvent = createAnalyticsEvent({
					action: 'click',
					actionSubject: 'queueSideBarIcon',
				});
				fireTrackAnalytics(sidebarIconClickEvent, 'sideBarIcon clicked');
				onSetIssueKey(selectedIssueKey);
			};

			return <SidebarIcon onClickHandler={onClickHandler} />;
		},
		[createAnalyticsEvent, onSetIssueKey],
	);

	const onJSMQueueDurationEvent = useCallback(() => {
		if (startTime) {
			const duration = Date.now() - startTime;
			fireTrackAnalytics(createAnalyticsEvent({}), 'jsmQueueIssue view', {
				duration,
			});
			// Only fire after the first click
			setStartTime(null);
		}
	}, [createAnalyticsEvent, startTime]);

	const onSidebarClose = useCallback(() => {
		onSetIssueKey(null);
	}, [onSetIssueKey]);

	const onIssueDeleteSuccess = useCallback(() => {
		onSetIssueKey(null);
		onIssueChange();
	}, [onIssueChange, onSetIssueKey]);

	useEffect(() => {
		setStartTime(Date.now());
	}, [setStartTime]);

	return (
		<ViewWrapper data-testid="servicedesk-queues-agent-view.layout.tablecontainer">
			<Box xcss={TableAndSidebarWrapperStyles}>
				<WidthObserver setWidth={throttledSetWidth} />
				<TableWrapper
					data-testid="servicedesk-queues-agent-view.layout.table"
					isResizing={isResizing}
					ref={ref}
				>
					<Table
						renderSidebarIcon={renderSidebarIcon}
						shouldRerenderRowOnHover
						useJSMQueueDurationEvent={onJSMQueueDurationEvent}
						width={width}
						tableWidth={tableWidth}
					/>
				</TableWrapper>
				<FFErrorBoundary onError={onError} id="sidebar-issue-view">
					{issueKey && (
						<SidebarIssueView
							key={reactKey}
							issueKey={issueKey}
							parentWidth={width}
							onClose={onSidebarClose}
							onIssueDeleteSuccess={onIssueDeleteSuccess}
							analyticsSource={SERVICEDESK_QUEUES_ISSUE}
							setIsResizing={setIsResizing}
							onChange={onChangeHandler}
						/>
					)}
				</FFErrorBoundary>
			</Box>

			{fg('jsm_boards_promo_gate') && <BoardPromoSpotlight />}
		</ViewWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LayoutContainer = styled.div<{ visible?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ visible = true }) => (visible ? 'flex' : 'none'),
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP})`,
	margin: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyStateWrapper = styled.div({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& div[data-component-selector='virtual-table.view.table-wrapper']": {
		height: token('space.600', '48px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyWrapper = styled.div({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '10%',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '50%',
	transform: 'translate(-50%)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewWrapperLegacy = styled.div({
	position: 'relative',
	display: 'flex',
	height: '100%',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewWrapperNew = styled.div({
	position: 'relative',
	display: 'flex',
	width: '100%',
	height: '100%',
	overflow: 'hidden',
});

const ViewWrapper = componentWithFG(
	'jsm-fission-decompose-queue-page-header',
	ViewWrapperNew,
	ViewWrapperLegacy,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableWrapper = styled.div<{ isResizing: boolean }>({
	display: 'flex',
	flexGrow: 3,
	overflowX: 'auto',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	userSelect: ({ isResizing }) => (isResizing ? 'none' : 'auto'),
});

const TableAndSidebarWrapperStyles = xcss({
	display: 'flex',
	width: '100%',
});

const jsmRepackagingBannerStyles = xcss({
	marginBottom: 'space.300',
});
