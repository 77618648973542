import { connect } from '../../../../../../../../../common/table-redux';
import type { ColumnId } from '../../../../../../../../../model/columns';
import { getColumnColor } from '../../../../../../../../../state/consumer/selectors/columns/index.tsx';
import type { State } from '../../../../../../../../../state/types';
import ChildBar from './index-dumb';

type Props = {
	columnId: ColumnId;
};

export default connect(
	(state: State, { columnId }: Props) => ({
		backgroundColor: getColumnColor(state, columnId),
	}),
	{},
)(ChildBar);
