import { connect } from '../../../../../../common/table-redux';
import type { ColumnId } from '../../../../../../model/columns';
import type { Optional } from '../../../../../../model/optional';
import type { RowId } from '../../../../../../model/rows';
import { navigateToCell } from '../../../../../../ops/cells/navigate/action.tsx';
import { clear as clearActiveItem } from '../../../../../../ops/items/active/clear/action.tsx';
import { setActiveCell } from '../../../../../../ops/items/active/set/action.tsx';
import {
	getCellComponent,
	canCellBeMultiLine,
	getColumnParentId,
} from '../../../../../../state/consumer/selectors/columns/index.tsx';
import {
	getActiveItemChangedCallback,
	getNonTemporaryCellWrapper,
	isInCellNavigationMode,
} from '../../../../../../state/consumer/selectors/index.tsx';

import { getMaxDepth } from '../../../../../../state/consumer/selectors/rows/index.tsx';
import {
	getActiveCell,
	getRenderSidebarIcon,
} from '../../../../../../state/internal/selectors.tsx';

import {
	getColumnWidth as getWidth,
	getRowDepth as getDepth,
	isCellActive,
	shouldRowHydrateFully,
} from '../../../../../../state/selectors/index.tsx';

import type { State } from '../../../../../../state/types';
import CellWrapper from './index-dumb';

type Props = {
	rowId: RowId;
	columnId: ColumnId;
	isRowTemporary: Optional<boolean>;
	isRowHovered: Optional<boolean>;
	isRowKeyboardActive: Optional<boolean>;
	confirmToNavigate: Optional<boolean>;
	onCellBlur: () => void; // remove on clean up of jsm-queue-sidebar-bulk-edit-bug-fix_jx9ff
	onCellMount: () => void;
};

export default connect(
	(state: State, { rowId, columnId, isRowHovered, isRowKeyboardActive }: Props) => {
		const activeItem = getActiveCell(state);
		const isActive = isCellActive(state, rowId, columnId);
		const activatedFrom = isActive && activeItem ? activeItem.activatedFrom : undefined;

		return {
			isInCellNavigationMode: isInCellNavigationMode(state),
			shouldHydrateFully: shouldRowHydrateFully(state, rowId),
			width: getWidth(state, columnId),
			depth: getDepth(state, rowId),
			maxDepth: getMaxDepth(state),
			isActive,
			activatedFrom,
			isRowHovered: isRowHovered || false,
			isRowKeyboardActive: isRowKeyboardActive || false,
			activeItemChanged: getActiveItemChangedCallback(state),
			CellComponent: getCellComponent(state, columnId),
			NonTemporaryCellWrapper: getNonTemporaryCellWrapper(state),
			canBeMultiLine: canCellBeMultiLine(state, columnId),
			parentId: getColumnParentId(state, columnId),
			renderSidebarIcon: getRenderSidebarIcon(state),
		};
	},
	{
		setActiveCell,
		clearActiveItem,
		navigateToCell,
	},
)(CellWrapper);
