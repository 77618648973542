import type { ComponentType } from 'react';
import type { PracticeQueueRouteParams } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/types';
import type { UseResourceCustomContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/types.tsx';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';

export type QueueNavItem = {
	id: string;
	name: string;
	url: string;
	badgeCount?: number;
	engagementId?: string;
	starred: boolean;
	canBeHidden?: boolean;
	after?: ComponentType<{
		isHover: boolean;
	}>;
	shouldSkipIssueCountRefresh?: boolean;
	category?: ItsmPractice | undefined;
	lastRefreshedTime?: number;
};

export type QueueResourceCustomContext = UseResourceCustomContext<PracticeQueueRouteParams>;

// TODO: go/restrict-enums
// TODO - Remove ENUMS https://jplat.jira.atlassian.cloud/browse/FSN-4507
// eslint-disable-next-line no-restricted-syntax
export enum QueueListSection {
	STARRED,
	PRIORITY_GROUP,
	ALL_QUEUES,
}

export type WithLatestUpdateTimestamp = {
	latestUpdateTimestamp: number;
};

type SelectedNavLocation = {
	selectedGroupId: string | undefined;
	selectedSection: QueueListSection | undefined;
} & WithLatestUpdateTimestamp;

type QueueId = string;
export type QueueNavState = Record<QueueId, SelectedNavLocation>;

type PoppedOutStatus = {
	isPoppedOut: boolean | undefined;
} & WithLatestUpdateTimestamp;

type CategoryKey = string;
export type QueuesMenuPoppedOutState = Record<CategoryKey, PoppedOutStatus>;

export type QueuesMenuIssueCountEnabledState = {
	queues: number[];
};
