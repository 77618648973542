import { connect } from '../../../../../../../common/table-redux';
import type { RowId } from '../../../../../../../model/rows';
import {
	getPremiumSLAColumns,
	shouldRerenderRowOnHover,
	getVisibleAdditionalColumnIds,
} from '../../../../../../../state/consumer/selectors';
import { getVisibleCoreColumnIds } from '../../../../../../../state/consumer/selectors/columns/index.tsx';
import { isActiveRowId } from '../../../../../../../state/selectors/index.tsx';
import type { State } from '../../../../../../../state/types';
import AdditionalColumnsContainer from '../../../../../common/additional-columns-container';
import CellWrapper from '../../cell-wrapper';
import ConditionalErrorBoundary from '../../conditional-error-boundary';
import RowChildOperationIndicator from './child-operation-indicator';
import RowContent from './index-dumb';

type Props = {
	id: RowId;
};

export default connect(
	(state: State, { id }: Props) => ({
		isActive: isActiveRowId(state, id),
		coreColumnIds: getVisibleCoreColumnIds(state),
		additionalColumnIds: getVisibleAdditionalColumnIds(state),
		shouldRerenderRowOnHover: shouldRerenderRowOnHover(state),
		premiumSLAColumnIds: getPremiumSLAColumns(state),
		AdditionalColumnsContainer,
		CellWrapper,
		RowChildOperationIndicator,
		ConditionalErrorBoundary,
	}),
	{},
)(RowContent);
