import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { useThemeObserver } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { usePathParam } from '@atlassian/jira-router';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import { MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH } from '../../../../../constants.tsx';
import { EMPTY_STATE_DATA } from './constants';
import { Description, LearnMoreLink } from './styled';

export const EmptyQueueAgentView = () => {
	const { formatMessage } = useIntl();
	const [practice] = usePathParam('practiceType');
	const { colorMode } = useThemeObserver();
	// practice should always be set in case of an unconfigured queue, if not this is a wrong route
	if (practice === undefined) {
		return null;
	}

	const { headerMessage, descriptionMessage, getImageUrl, learnMoreText, learnMoreUrl } =
		EMPTY_STATE_DATA[toItsmPractice(practice)];

	return (
		<EmptyState
			header={formatMessage(headerMessage)}
			description={<Description>{formatMessage(descriptionMessage)}</Description>}
			imageUrl={getImageUrl(colorMode)}
			primaryAction={
				learnMoreText &&
				learnMoreUrl && (
					<LearnMoreLink target="_blank" href={learnMoreUrl}>
						{formatMessage(learnMoreText)}
					</LearnMoreLink>
				)
			}
			maxImageWidth={MAX_IMAGE_WIDTH}
			maxImageHeight={MAX_IMAGE_HEIGHT}
			testId="servicedesk-queues-empty-queue.common.ui.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-agent-view.empty-queue-agent-view"
		/>
	);
};
