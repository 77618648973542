import { connect } from '../../../../../../../../../common/table-redux';
import type { ColumnId } from '../../../../../../../../../model/columns';
import {
	isParentColumn,
	isColumnExpanded,
	getColumnHasChildren,
	getColumnColor,
} from '../../../../../../../../../state/consumer/selectors/columns/index.tsx';
import { getColumnExpandCallback } from '../../../../../../../../../state/consumer/selectors/index.tsx';
import type { State } from '../../../../../../../../../state/types';
import ColumnChildrenOperationIndicator from './index-dumb';

type Props = {
	columnId: ColumnId;
};

export default connect(
	(state: State, { columnId }: Props) => ({
		isParentColumn: isParentColumn(state, columnId),
		isColumnExpanded: isColumnExpanded(state, columnId),
		columnHasChildren: getColumnHasChildren(state, columnId),
		color: getColumnColor(state, columnId),
		onColumnExpansionChangeRequested: getColumnExpandCallback(state),
	}),
	{},
)(ColumnChildrenOperationIndicator);
