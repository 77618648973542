import { ACTIVE_CELL_TYPE } from '../../../model/navigation';
import { getVisibleCoreColumnIds } from '../../../state/consumer/selectors/columns';
import {
	setActiveItem,
	setVerticalScrollOffset,
	clearActiveItem,
} from '../../../state/internal/actions';
import { getScrollOffsetForRowIndex } from '../../../state/selectors';
import { getFlattenedWithAddedRowIds as getVisibleRowIds } from '../../../state/selectors/visible-row-ids/index.tsx';
import type { State } from '../../../state/types';
import { defineTransaction } from '../../common/transactions';
import { SCROLL_TO_ROW, type ScrollToRowAction } from './action';

export default defineTransaction(SCROLL_TO_ROW, (action: ScrollToRowAction, state: State) => {
	const rowIds = getVisibleRowIds(state);
	const rowIndex = rowIds.indexOf(action.payload);

	// if row is invalid/not visible then clear the active item
	if (rowIndex === -1) {
		return clearActiveItem();
	}

	// A row is 36px high, including the 1px border. These borders
	// overlap leading to an offset height for scrolling as 36px - 1px
	return [
		setActiveItem({
			type: ACTIVE_CELL_TYPE,
			rowId: action.payload,
			columnId: getVisibleCoreColumnIds(state)[0],
		}),
		setVerticalScrollOffset(getScrollOffsetForRowIndex(state, rowIndex)),
	] as const;
});
