import React, { useCallback, useEffect, useState } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import BoardPromoSpotlightView from './board-promo-spotlight-view';

const BoardPromoSpotlightWithDelay = () => {
	const [shouldShowBoardPromoSpotlight, setShouldShowBoardPromoSpotlight] =
		useState<boolean>(false);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setShouldShowBoardPromoSpotlight(true);
		}, 2000);
		return () => clearTimeout(timeoutId);
	}, []);
	return shouldShowBoardPromoSpotlight && <BoardPromoSpotlightView />;
};

export const BoardPromoSpotlight = () => {
	const emptyFallback = useCallback(() => <></>, []);
	return (
		<JSErrorBoundary
			id="jira-servicedesk-queues-board-promotion-spotlight"
			packageName="jiraServicedeskQueuesAgentView"
			teamName="jsd-shield"
			fallback={emptyFallback}
		>
			<BoardPromoSpotlightWithDelay />
		</JSErrorBoundary>
	);
};
