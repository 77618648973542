import React, { forwardRef, useState, type ReactElement } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { NudgeSpotlight } from '@atlassiansox/nudge-tooltip';
import { layers } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';
import CoordinationClient from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import Illustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/components/board';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/jira-router';
import { usePageContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-context/main.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { createOnboardingSpotlight } from '@atlassian/jira-software-onboarding-nudges--next/src/common/ui/onboarding-spotlight';
import spotlightImage from '../../../../common/assets/boards.png';
import { useBoardPromotion } from '../../../../ops/common/board-promotion';
import messages from '../messages';

type StopFunction = () => void;
type CoordinationStopProviderProps = {
	stop?: StopFunction;
	children: (stop: StopFunction | undefined) => ReactElement;
};

export interface BoardSpotlightProps {
	isAdmin?: boolean;
	projectKey?: ProjectKey;
}

export interface BoardSpotlightShowDeciderProps {
	stop?: () => void;
	isAdmin?: boolean;
	projectKey?: ProjectKey;
	renderSpotlightCard: (stop?: StopFunction) => React.JSX.Element;
}

const QUEUES_ADMIN_MESSAGE_ID = 'jira-jsm-boards-promotion-admin';
const QUEUES_AGENT_MESSAGE_ID = 'jira-jsm-boards-promotion-agent';

export const CoordinationStopProvider = ({ stop, children }: CoordinationStopProviderProps) =>
	children(stop);

const BoardSpotlightShowDecider = ({
	stop,
	isAdmin,
	projectKey,
	renderSpotlightCard,
}: BoardSpotlightShowDeciderProps) => {
	const { shouldRenderBoardPromotion } = useBoardPromotion(isAdmin, projectKey);

	return shouldRenderBoardPromotion ? (
		<CoordinationClient messageId={isAdmin ? QUEUES_ADMIN_MESSAGE_ID : QUEUES_AGENT_MESSAGE_ID}>
			<CoordinationStopProvider stop={stop}>{renderSpotlightCard}</CoordinationStopProvider>
		</CoordinationClient>
	) : null;
};

export const BoardPromoSpotlightView = ({ isAdmin, projectKey }: BoardSpotlightProps) => {
	const [showSpotlight, setShowSpotlight] = useState<boolean>(true);
	const { push } = useRouterActions();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ data: pageContextData }] = usePageContext();

	const { projectId, projectType } = pageContextData?.projectContext || {};

	const renderSpotlightCard = (stop?: StopFunction) => {
		const onDismissOnboarding = () => {
			stop?.();
			setShowSpotlight(false);
		};

		const handleEnableBoard = () => {
			if (projectKey) {
				const enableBoardsUrl = `/jira/servicedesk/projects/${projectKey}/settings/itsm-features?queues-board-promotion=true`;
				push(enableBoardsUrl);
				onDismissOnboarding();
			}
		};

		const handleLearnMore = () => {
			onDismissOnboarding();
			window.open(
				'https://support.atlassian.com/jira-service-management-cloud/docs/manage-your-work-using-a-board/',
				'_blank',
				'noreferrer',
			);
		};

		const renderNudge = () => {
			const SpotlightRef = createOnboardingSpotlight({
				actions: [
					isAdmin
						? {
								onClick: () => {
									fireUIAnalytics(
										createAnalyticsEvent({}),
										'button clicked',
										'boardAdminActivationSpotlightDismiss',
										{
											projectId,
											projectType,
										},
									);
									onDismissOnboarding();
								},
								content: formatMessage(messages.dismiss),
								appearance: 'subtle',
							}
						: {
								onClick: () => {
									fireUIAnalytics(
										createAnalyticsEvent({}),
										'button clicked',
										'boardAgentActivationSpotlightLearnMore',
										{
											projectId,
											projectType,
										},
									);
									handleLearnMore();
								},
								content: formatMessage(messages.learnMore),
								appearance: 'subtle',
							},
					isAdmin
						? {
								onClick: () => {
									fireUIAnalytics(
										createAnalyticsEvent({}),
										'button clicked',
										'boardAdminActivationSpotlightActivate',
										{
											projectId,
											projectType,
										},
									);
									handleEnableBoard();
								},
								content: formatMessage(messages.enable),
								appearance: 'primary',
							}
						: {
								onClick: () => {
									fireUIAnalytics(
										createAnalyticsEvent({}),
										'button clicked',
										'boardAgentActivationSpotlightOk',
										{
											projectId,
											projectType,
										},
									);
									onDismissOnboarding();
								},
								content: formatMessage(messages.ok),
								appearance: 'primary',
							},
				],
				contentMessage: isAdmin
					? formatMessage(messages.adminDescription)
					: formatMessage(messages.agentDescription),
				image: fg('jsm_queues_consolidate_jfe_assets') ? (
					<Illustration alt="" width="320" />
				) : (
					<img src={spotlightImage} alt="" width="320" />
				),
				headingMessage: formatMessage(messages.heading),
			});

			// eslint-disable-next-line react/no-unstable-nested-components
			return forwardRef<HTMLDivElement>((_, ref) => (
				<div aria-label="promotion" ref={ref}>
					<SpotlightRef />
				</div>
			));
		};

		return (
			<ModalWrapperStyles aria-label="promotion">
				<ContextualAnalyticsData
					sourceType={SCREEN}
					sourceName={`board${isAdmin ? 'Admin' : 'Agent'}ActivationSpotlight`}
				>
					{/* @ts-expect-error TS2741: Property 'children' is missing in type */}
					<NudgeSpotlight
						setHidden={noop}
						hidden={!showSpotlight}
						spotlightCard={renderNudge()}
						position="bottom-end"
						zIndex={layers.spotlight()}
						hideSpotlightCardOnOutsideClick={false}
					/>
					<FireScreenAnalytics
						attributes={{
							messageId: isAdmin ? QUEUES_ADMIN_MESSAGE_ID : QUEUES_AGENT_MESSAGE_ID,
							name: `board${isAdmin ? 'Admin' : 'Agent'}ActivationSpotlight`,
							projectId,
							projectType,
						}}
					/>
				</ContextualAnalyticsData>
			</ModalWrapperStyles>
		);
	};

	return showSpotlight ? (
		<BoardSpotlightShowDecider
			isAdmin={isAdmin}
			projectKey={projectKey}
			renderSpotlightCard={renderSpotlightCard}
		/>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalWrapperStyles = styled.div({
	position: 'absolute',
	bottom: token('space.400', '32px'),
	right: token('space.600', '48px'),
});
