import { NOT_FOUND } from '@atlassian/jira-common-constants/src/http-status-codes';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { PACKAGE_NAME, PACKAGE_TEAM_NAME } from '../../common/constants';

export const getProjectCreationUTCDate = async ({ projectKey }: { projectKey: string }) => {
	try {
		if (!projectKey) {
			return '';
		}
		const result = await performGetRequest(
			`/rest/servicedesk/latest/xflow/project/creation-time/${projectKey}`,
		);
		const { projectCreatedTime = '' } = result || {};
		return projectCreatedTime;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (error.statusCode !== NOT_FOUND) {
			fireErrorAnalytics({
				meta: {
					id: 'getProjectCreationUTCDate',
					packageName: PACKAGE_NAME,
					teamName: PACKAGE_TEAM_NAME,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		}
		return '';
	}
};
