import { getIn, dissoc } from 'icepick';
import type { CategorizedQueueData } from '@atlassian/jira-router-resources-service-desk-queues/src/types.tsx';
import type { Page } from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { getQueuesForCategory, queuesToNavItems } from '../common/utils';
import type { NavItem, Data, PracticeMetadata } from '../types';

export type DataSelector<T> = (data: Data<CategorizedQueueData>, category: ItsmPractice) => Data<T>;

export const queuesDataSelector = (
	data: Data<CategorizedQueueData>,
	category: ItsmPractice,
): Data<Page[]> => getQueuesForCategory(data, category) || null;

export const navDataSelector = (
	data: Data<CategorizedQueueData>,
	category: ItsmPractice,
): Data<NavItem[]> => {
	const queues = getQueuesForCategory(data, category);
	return queues ? queuesToNavItems(queues) : null;
};

// Used in milestone2 for the hidden section
export const notFavouriteAndCanBeHiddenDataSelector = (
	data: Data<CategorizedQueueData>,
	category: ItsmPractice,
): Data<NavItem[]> => {
	const queues = getQueuesForCategory(data, category);
	const otherQueuesData = queues
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			queues.filter((q) => (q as Page).canBeHidden && !(q as Page).favourite)
		: null;
	return otherQueuesData ? queuesToNavItems(otherQueuesData) : null;
};

// used in milestone1 for the always visible (other) section
export const notFavouriteDataSelector = (
	data: Data<CategorizedQueueData>,
	category: ItsmPractice,
): Data<NavItem[]> => {
	const queues = getQueuesForCategory(data, category);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const otherQueuesData = queues ? queues.filter((q) => !(q as Page).favourite) : null;
	return otherQueuesData ? queuesToNavItems(otherQueuesData) : null;
};

export const cannotBeHiddenAndNotFavouriteDataSelector = (
	data: Data<CategorizedQueueData>,
	category: ItsmPractice,
): Data<NavItem[]> => {
	const queues = getQueuesForCategory(data, category);
	const defaultQueuesData = queues
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			queues.filter((q) => !(q as Page).canBeHidden && !(q as Page).favourite)
		: null;
	return defaultQueuesData ? queuesToNavItems(defaultQueuesData) : null;
};

export const favouriteDataSelector = (
	data: Data<CategorizedQueueData>,
	category: ItsmPractice,
): Data<NavItem[]> => {
	const queues = getQueuesForCategory(data, category);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const starredQueues = queues ? queues.filter((q) => (q as Page).favourite) : null;
	return starredQueues ? queuesToNavItems(starredQueues) : null;
};

export const practiceMetadataSelector = (
	data: Data<CategorizedQueueData>,
	category: ItsmPractice,
): Data<PracticeMetadata> => {
	const practiceData = getIn(data, [category]);
	return practiceData ? dissoc(practiceData, 'queues') : null;
};

export const isCountLoadingSelector = (
	data: Data<CategorizedQueueData>,
	category: ItsmPractice,
): boolean => {
	const practiceData = getIn(data, [category]);
	return practiceData ? practiceData.isCountLoading : false;
};

export const hasRequestedCountSelector = (
	data: Data<CategorizedQueueData>,
	category: ItsmPractice,
): boolean => {
	const practiceData = getIn(data, [category]);
	return practiceData ? practiceData.hasRequestedCount : false;
};

export const practiceListSelector = (data: Data<CategorizedQueueData>): Data<ItsmPractice[]> =>
	// @ts-expect-error - TS2322 - Type 'string[] | null' is not assignable to type 'Data<ItsmPractice[]>'.
	data ? Object.keys(data) : null;
