// @ts-expect-error - TS2305 - Module '"monet"' has no exported member 'MaybeType'.
import { type MaybeType, Maybe } from 'monet';
import {
	ASC,
	type SortOrder,
} from '@atlassian/jira-issue-table/src/model/fields/sort-order/index.tsx';
import type { ManualSorting } from '../../reducers/persisted/queue/types';
import type { State } from '../../reducers/types';
import { getColumns, getQueuePageData } from '../queue';
import { getCurrentQueue } from '../ui';

const maybeManualSorting = (state: State): MaybeType<ManualSorting> => {
	const queue = getCurrentQueue(state);
	return Maybe.fromNull((queue && queue.manualSorting) || getQueuePageData(state).manualSorting);
};

export const getSortedByColumn = (state: State): string | undefined =>
	maybeManualSorting(state)
		// @ts-expect-error - TS7006 - Parameter 'manualSorting' implicitly has an 'any' type.
		.map((manualSorting) => manualSorting.sortedBy)
		.orSome(undefined);

// Gets the sorted field type whether if sorted by JQL or user manual sorting
export const getSortFieldType = (state: State): string | undefined => {
	const sortedColumn = getColumns(state).find((column) => Boolean(column.currentSorting));
	return sortedColumn ? sortedColumn.fieldType : undefined;
};

export const getSortOrder = (state: State): SortOrder =>
	maybeManualSorting(state)
		// @ts-expect-error - TS7006 - Parameter 'manualSorting' implicitly has an 'any' type.
		.map((manualSorting) => manualSorting.sortOrder)
		.orSome(ASC);

export const isManuallySorted = (state: State): boolean => getSortedByColumn(state) !== undefined;

export const getIsAscendingOrder = (state: State): boolean => getSortOrder(state) === ASC;
