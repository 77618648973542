import { connect } from '../../../../../../../common/table-redux';
import type { RowId } from '../../../../../../../model/rows';
import { getRowAddCallback } from '../../../../../../../state/consumer/selectors/index.tsx';
import {
	getRowAddPosition,
	isRowExpanded,
	getRowCanAddSibling,
	getRowCanAddChildren,
} from '../../../../../../../state/consumer/selectors/rows/index.tsx';
import type { State } from '../../../../../../../state/types';
import AddBar from './index-dumb';

type Props = {
	rowId: RowId;
};

export default connect(
	(state: State, { rowId }: Props) => ({
		addPosition: getRowAddPosition(state, rowId),
		isRowExpanded: isRowExpanded(state, rowId),
		canAddSibling: getRowCanAddSibling(state, rowId),
		canAddChildren: getRowCanAddChildren(state, rowId),
		onRowAddRequested: getRowAddCallback(state),
	}),
	{},
)(AddBar);
