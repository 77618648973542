import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { UpdateQueuesVisibility } from '../../types';

const getUpdateQueuesVisibilityUrl = (baseUrl: string, projectKey: string): string =>
	`${baseUrl}/rest/servicedesk/1/servicedesk/${projectKey}/queues/update-visibility`;

export const updateQueuesVisibility: UpdateQueuesVisibility = (baseUrl, projectKey, modified) =>
	performPutRequest(getUpdateQueuesVisibilityUrl(baseUrl, projectKey), {
		body: JSON.stringify({ modified }),
	});
