import { connect } from '../../../../common/table-redux';
import { getVisibleCoreColumnsWidth } from '../../../../state/consumer/selectors/columns/index.tsx';
import type { State } from '../../../../state/types';
import AddLink from './add-link';
import UtilityRow from './index-dumb';
import ScrollArea from './scroll-area';

export default connect(
	(state: State) => ({
		coreColumnsWidth: getVisibleCoreColumnsWidth(state),
		AddLink,
		ScrollArea,
	}),
	{},
)(UtilityRow);
