import React from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { usePathParam } from '@atlassian/jira-router';
import { useQueueCustomRouterContext } from '@atlassian/jira-servicedesk-common/src/navigation/queues/use-queue-custom-router-context';
import { QueueListSection } from '@atlassian/jira-servicedesk-queues-categorized-store/src/controllers/navigation-category';
import { useFavouriteNavItems } from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import {
	ExpandableMenuItem,
	MenuSection,
	MenuSectionHeading,
	ExpandableMenuItemContent,
	ExpandableMenuItemTrigger,
} from '@atlassian/navigation-system';
import { usePopoutConfig } from '../../../../utils/popout-view';
import { useSelectedNavLocation } from '../../../controllers/nav-location';
import { QueueList } from '../queue-list/main.tsx';
import { messages } from './messages';

type StarredSectionProps = {
	projectKey: string;
	practice: ItsmPractice;
};

export const StarredSection = ({ practice, projectKey }: StarredSectionProps) => {
	const { formatMessage } = useIntl();

	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const popoutConfig = usePopoutConfig(projectKey, practice);
	const [queues] = useFavouriteNavItems('', projectKey, practice, queueCustomContext);
	const [queueIdFromPath] = usePathParam('queueId');
	const [selectedNavLocation] = useSelectedNavLocation(queueIdFromPath || '');

	if (!queues || queues.length === 0) {
		return null;
	}

	if (ff('jsm-queues-nav4-refresh-m2-queues-pop-out-view_atq13') && popoutConfig.isPoppedOut) {
		return (
			<ExpandableMenuItem
				isDefaultExpanded={selectedNavLocation.section === QueueListSection.STARRED}
			>
				<ExpandableMenuItemTrigger>
					{formatMessage(messages.starredHeader)}
				</ExpandableMenuItemTrigger>
				<ExpandableMenuItemContent>
					<QueueList queues={queues} section={QueueListSection.STARRED} projectKey={projectKey} />
				</ExpandableMenuItemContent>
			</ExpandableMenuItem>
		);
	}

	return (
		<MenuSection>
			<MenuSectionHeading>{formatMessage(messages.starredHeader)}</MenuSectionHeading>
			<QueueList queues={queues} section={QueueListSection.STARRED} projectKey={projectKey} />
		</MenuSection>
	);
};
