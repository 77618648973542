import { useState } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import type { ButtonClickProps, FormDataProps, FormOnSubmitProps } from '../../common/types';
import {
	IMPORT_OPTIONS_VALUES,
	IMPORT_URL,
	LOCAL_STORAGE_ID,
	LOCAL_STORAGE_KEY,
} from './constants';

const storage = createLocalStorageProvider(LOCAL_STORAGE_ID);

export const useSurveyModalController = ({ isFromEmptyState = false }) => {
	const [showModal, setShowModal] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onImport: ButtonClickProps = (_, analyticsEvent) => {
		const isModalResponseAdded = storage.get(LOCAL_STORAGE_KEY);
		// check if user has submitted the modal already
		if (isModalResponseAdded) {
			window.open(IMPORT_URL, '_blank');
		} else {
			setShowModal(true);
		}
		fireUIAnalytics(analyticsEvent, 'jsmImporterSurveyImportIssuesButton', {
			isFromEmptyState,
			isModalShown: !isModalResponseAdded,
		});
	};
	const onClose: ButtonClickProps = (_, analyticsEvent) => {
		setShowModal(false);
		fireUIAnalytics(analyticsEvent, 'jsmImporterSurveyCloseModalButton');
	};

	const getDataForTrackEvent = (data: FormDataProps) => {
		const selectedImportTool = data.importTool?.value || '';
		const selectedImportOptions = data.importOptions?.map((item) => item.value) || [];
		const yesNoValForImportOptions = {};

		IMPORT_OPTIONS_VALUES.forEach((importOption) => {
			Object.assign(yesNoValForImportOptions, {
				[importOption]: selectedImportOptions.includes(importOption) ? 'Yes' : 'No',
			});
		});

		const sortSelectedOptionsAlphabetically = (options: string[]) =>
			options.sort((a, b) => a.localeCompare(b));

		return {
			selectedImportTool,
			selectedImportOptions: sortSelectedOptionsAlphabetically(selectedImportOptions),
			...yesNoValForImportOptions,
		};
	};

	const onSubmit: FormOnSubmitProps = (data) => {
		setShowModal(false);
		// navigate to the importer page
		window.open(IMPORT_URL, '_blank');
		const { importTool, importOptions } = data;

		const atLeastOneDataAvailable = !!importOptions || !!importTool;
		if (atLeastOneDataAvailable) {
			// update the storage even if user selected single option
			storage.set(LOCAL_STORAGE_KEY, true);
		}
		fireTrackAnalytics(
			createAnalyticsEvent({}),
			'jsmImporterSurvey.options Selected',
			getDataForTrackEvent(data),
		);
	};

	return {
		showModal,
		onSubmit,
		onImport,
		onClose,
	};
};
