import { connect } from '../../../../../../common/table-redux';
import type { RowId } from '../../../../../../model/rows';
import {
	isTreeTableFacade,
	isContentStale,
	disableRowHighlight,
} from '../../../../../../state/consumer/selectors';
import { isRowSelected } from '../../../../../../state/consumer/selectors/rows/index.tsx';
import {
	getOffsetFromLeft,
	getOffsetFromRight,
	isGlobalAddEnabled,
	getSetFocusOnRow,
	isActiveRowItem,
} from '../../../../../../state/selectors';
import type { State } from '../../../../../../state/types';
import AddBar from './add-bar';
import Content from './content';
import DefaultRow from './index-dumb';

type Props = {
	id: RowId;
};

export default connect((state: State, { id }: Props) => {
	const isActiveItem = isActiveRowItem(state, id);
	const isRowHighlighted =
		isActiveItem &&
		!isTreeTableFacade(state) &&
		!isContentStale(state) &&
		!disableRowHighlight(state);
	const isRowLeftEdgeVisible = getOffsetFromLeft(state) === 0;
	const isRowRightEdgeVisible = getOffsetFromRight(state) === 0;

	return {
		isActiveItem,
		isRowHighlighted,
		isRowLeftEdgeVisible,
		isRowRightEdgeVisible,
		isGlobalAddEnabled: isGlobalAddEnabled(state),
		setFocusOnRow: getSetFocusOnRow(state, id),
		isSelected: isRowSelected(state, id),
		AddBar,
		Content,
	};
}, {})(DefaultRow);
