import { connect } from '../../../../common/table-redux';
import { showBottomDecal } from '../../../../state/consumer/selectors';
import { getVisibleCoreColumnsWidth } from '../../../../state/consumer/selectors/columns/index.tsx';
import { getTableWidthWithoutScrollbarOffset } from '../../../../state/internal/selectors';
import {
	getTableHeight,
	hasHorizontalScrollbar,
	getOffsetFromBottom,
	isHorizontalScrollLeftShadowVisible,
	isHorizontalScrollRightShadowVisible,
} from '../../../../state/selectors';
import type { State } from '../../../../state/types';
import HorizontalScrollOverlay from './index-dumb';

export default connect(
	(state: State) => ({
		getOffsetFromBottom: getOffsetFromBottom(state),
		isHorizontalScrollVisible: hasHorizontalScrollbar(state),
		offset: getVisibleCoreColumnsWidth(state),
		showBottomDecal: showBottomDecal(state),
		showLeftShadow: isHorizontalScrollLeftShadowVisible(state),
		showRightShadow: isHorizontalScrollRightShadowVisible(state),
		tableHeight: getTableHeight(state),
		tableWidth: getTableWidthWithoutScrollbarOffset(state),
	}),
	{},
)(HorizontalScrollOverlay);
