import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { getMatchedRoute } from '@atlassian/jira-platform-router-utils/src/common/utils/index.tsx';
import { useQueueCustomRouterContext } from '@atlassian/jira-servicedesk-common/src/navigation/queues/use-queue-custom-router-context';
import { QueueListSection } from '@atlassian/jira-servicedesk-queues-categorized-store/src/controllers/navigation-category';
import { useCanBeHiddenNavItems } from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import {
	ExpandableMenuItem,
	ExpandableMenuItemContent,
	ExpandableMenuItemTrigger,
} from '@atlassian/navigation-system';
import { QueueList } from '../queue-list/main.tsx';
import { messages } from './messages';

type HiddenProps = {
	projectKey: string;
	practice: ItsmPractice;
};

export const HiddenSection = ({ practice, projectKey }: HiddenProps) => {
	const { formatMessage } = useIntl();

	// TODO This baseUrl has a dependency o@atlassian/jira-servicedesk-queues-categorized-store
	// The above items need to be updated prior to complete baseUrl removal from this file.
	const baseUrl = '';
	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const [hiddenQueues] = useCanBeHiddenNavItems(baseUrl, projectKey, practice, queueCustomContext);
	const queueId = getMatchedRoute()?.match.params.queueId;
	const isHiddenQueueSelected =
		hiddenQueues != null && hiddenQueues.find((queue) => queue.id === queueId) != null;

	if (hiddenQueues && hiddenQueues.length !== 0) {
		return (
			<ExpandableMenuItem isDefaultExpanded={isHiddenQueueSelected}>
				<ExpandableMenuItemTrigger>{formatMessage(messages.others)}</ExpandableMenuItemTrigger>
				<ExpandableMenuItemContent>
					<QueueList queues={hiddenQueues} section={QueueListSection.ALL_QUEUES} />
				</ExpandableMenuItemContent>
			</ExpandableMenuItem>
		);
	}

	return null;
};
