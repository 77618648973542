import { useMemo } from 'react';
import { usePathParam } from '@atlassian/jira-router';
import type { GroupWithQueues } from '@atlassian/jira-router-resources-service-desk-queues/src/types';
import { useQueueCustomRouterContext } from '@atlassian/jira-servicedesk-common/src/navigation/queues/use-queue-custom-router-context';
import { QueueListSection } from '@atlassian/jira-servicedesk-queues-categorized-store/src/controllers/navigation-category';
import {
	useCategorizedGroupsNavItems,
	usePriorityGroups,
} from '@atlassian/jira-servicedesk-queues-categorized-store/src/controllers/priority-group';
import {
	NO_CATEGORY,
	type ItsmPractice,
} from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { useSelectedNavLocation } from '../../../controllers/nav-location';
import type { QueueNavItem } from '../../../types';

export const calculateSelectedSection = (
	queue: QueueNavItem,
	priorityGroups: GroupWithQueues[],
	lastSelectedSection: QueueListSection | undefined,
	currentSection: QueueListSection,
	selectedGroupId?: string,
) => {
	if (currentSection === lastSelectedSection) {
		return currentSection;
	}
	const isStarred = queue.starred;
	const selectedPriorityGroup = priorityGroups.find((group) => group.id === selectedGroupId);
	const isQueueInSelectedGroup = selectedPriorityGroup?.queues.some(
		(queueInGroup) => queueInGroup.toString(10) === queue.id,
	);

	if (lastSelectedSection === QueueListSection.STARRED && !queue.starred) {
		return isQueueInSelectedGroup ? QueueListSection.PRIORITY_GROUP : QueueListSection.ALL_QUEUES;
	}

	if (lastSelectedSection === QueueListSection.PRIORITY_GROUP && !isQueueInSelectedGroup) {
		return isStarred ? QueueListSection.STARRED : QueueListSection.ALL_QUEUES;
	}

	if (lastSelectedSection === undefined) {
		if (isStarred) {
			return QueueListSection.STARRED;
		}
		if (isQueueInSelectedGroup) {
			return QueueListSection.PRIORITY_GROUP;
		}
		return QueueListSection.ALL_QUEUES;
	}
	return lastSelectedSection;
};

// calculate wether a queue should be selected, based on the users history and its position in the sidebar.
export const getIsQueueSelected = (
	queue: QueueNavItem,
	priorityGroups: GroupWithQueues[],
	section: QueueListSection,
	lastSelectedSection?: QueueListSection,
	lastSelectedGroupId?: string,
	priorityGroupId?: string,
) => {
	const firstPriorityGroupWithQueue = priorityGroups.find((group) =>
		group.queues.includes(Number(queue.id)),
	)?.id;
	const queueMissingValidHistory =
		lastSelectedSection === undefined ||
		(lastSelectedSection === QueueListSection.PRIORITY_GROUP && !lastSelectedGroupId);

	const isQueueSelectedBasedOnPriority: Record<QueueListSection, boolean> = {
		[QueueListSection.STARRED]: queue.starred && !priorityGroupId,
		[QueueListSection.PRIORITY_GROUP]:
			!queue.starred && priorityGroupId === firstPriorityGroupWithQueue,
		[QueueListSection.ALL_QUEUES]: !queue.starred && !firstPriorityGroupWithQueue,
	};
	const isQueueSelectedBasedOnHistory: Record<QueueListSection, boolean> = {
		[QueueListSection.STARRED]: true,
		[QueueListSection.PRIORITY_GROUP]: lastSelectedGroupId === priorityGroupId,
		[QueueListSection.ALL_QUEUES]: true,
	};

	if (queueMissingValidHistory) {
		return isQueueSelectedBasedOnPriority[section];
	}

	if (section === lastSelectedSection) {
		return isQueueSelectedBasedOnHistory[lastSelectedSection];
	}

	return false;
};

export const useIsQueueSelected = (
	queue: QueueNavItem | undefined,
	section: QueueListSection,
	priorityGroupId?: string,
) => {
	const [queueIdFromPath] = usePathParam('queueId');
	const [selectedNavLocation] = useSelectedNavLocation(queueIdFromPath || '');
	const [priorityGroups] = usePriorityGroups(queue?.category || NO_CATEGORY);

	return useMemo(
		() =>
			queue !== undefined
				? getIsQueueSelected(
						queue,
						priorityGroups,
						section,
						selectedNavLocation.section,
						selectedNavLocation.groupId,
						priorityGroupId,
					)
				: false,
		[
			priorityGroupId,
			priorityGroups,
			queue,
			section,
			selectedNavLocation.groupId,
			selectedNavLocation.section,
		],
	);
};

export const useFilteredCategorizedQueueGroups = (
	projectKey: string,
	category: ItsmPractice,
): ReturnType<typeof useCategorizedGroupsNavItems> => {
	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const [groups, isLoading, groupError, queueError] = useCategorizedGroupsNavItems(
		projectKey,
		category,
		queueCustomContext,
	);

	const filteredGroups = useMemo(() => groups.filter((group) => group.queues.length > 0), [groups]);

	return [filteredGroups, isLoading, groupError, queueError];
};
