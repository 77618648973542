import { assocIn } from 'icepick';
import keyBy from 'lodash/keyBy';
import noop from 'lodash/noop';
import sortBy from 'lodash/sortBy';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics';
import type { Page } from '@atlassian/jira-servicedesk-queues-common/src/rest/common/types';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { getQueuesForCategory, setQueuesForCategory } from '../../../../common/utils';
import { hasRequestedCountSelector } from '../../../../selectors';
import type { Updater } from '../types';
import {
	fireErrorAnalyticsForRefreshCountFailure,
	fireManualOperationalAnalytics,
	updateIssueCountForQueues,
} from './api';

type RestParams = {
	baseUrl: string;
	projectKey: string;
	category: ItsmPractice;
};

export const MAX_RETRY_COUNT = 5;
const RETRY_DELAY_IN_MS = 1000;

const fireErrorAnalyticsForRetryFailure = (error: Error) => {
	fireErrorAnalytics({
		meta: {
			id: 'retryQueueIssueCountAction',
			packageName: 'jiraServicedeskCategorizedQueuesStore',
			teamName: 'jsd-shield',
		},
		error,
	});
};

export const retryIssueCountFetching =
	(
		{ baseUrl, projectKey, category }: RestParams,
		skipHiddenQueues: boolean,
		update: Updater,
		retryCount: number,
		analyticsEvent?: UIAnalyticsEvent,
	) =>
	async (queueIds: number[]) => {
		if (queueIds && queueIds.length === 0) {
			return;
		}

		if (retryCount >= MAX_RETRY_COUNT) {
			fireManualOperationalAnalytics({
				source: 'refreshQueueIssueCountAction',
				actionSubject: 'retryIssueCount',
				action: 'limitExceeded',
				attributes: {
					retryCount,
					maxRetryCount: MAX_RETRY_COUNT,
				},
			});
			return;
		}

		setTimeout(() => {
			try {
				const newRetryCount = retryCount + 1;
				update((data) => {
					const queues = getQueuesForCategory(data, category)?.filter((queue: Page) =>
						queueIds.includes(queue.id),
					);

					if (queues && queues.length > 0) {
						const retryCallback = retryIssueCountFetching(
							{ baseUrl, projectKey, category },
							true,
							update,
							newRetryCount,
							analyticsEvent,
						);

						updateIssueCountForQueues(
							baseUrl,
							projectKey,
							category,
							queues,
							skipHiddenQueues,
							update,
							retryCallback,
							analyticsEvent,
						);
						fireManualOperationalAnalytics({
							source: 'refreshQueueIssueCountAction',
							actionSubject: 'retryQueueIssueCount',
							action: 'triggered',
							attributes: {
								retryCount: newRetryCount,
								maxRetryCount: MAX_RETRY_COUNT,
								queuesCount: queues.length,
							},
						});
						return data ? assocIn(data, [category, 'isCountLoading'], true) : null;
					}
					return data;
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalyticsForRetryFailure(error);
				throw error;
			}
		}, RETRY_DELAY_IN_MS);
	};

export const refreshIssueCountForVisibleAndStarredQueues = async (
	update: Updater,
	{ baseUrl, projectKey, category }: RestParams,
	analyticsEvent?: UIAnalyticsEvent,
) => {
	try {
		update((data) => {
			const queues = getQueuesForCategory(data, category);
			const retryCallback = !hasRequestedCountSelector(data, category)
				? retryIssueCountFetching(
						{ baseUrl, projectKey, category },
						true,
						update,
						0,
						analyticsEvent,
					)
				: noop;

			if (queues) {
				updateIssueCountForQueues(
					baseUrl,
					projectKey,
					category,
					queues,
					true,
					update,
					retryCallback,
					analyticsEvent,
				);
				return data ? assocIn(data, [category, 'isCountLoading'], true) : null;
			}
			return data;
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalyticsForRefreshCountFailure(error);
		throw error;
	}
};

export const enableRefreshForQueues = (
	update: Updater,
	{ projectKey: _, category }: RestParams,
	queueIds: string[],
) => {
	update((data) => {
		const queues = getQueuesForCategory(data, category);
		const refreshEnabledQueues = queues
			?.filter((queue: Page) => queueIds.includes(queue.id.toString()))
			.map((queue) =>
				queue.shouldSkipIssueCountRefresh
					? {
							...queue,
							shouldSkipIssueCountRefresh: false,
							count: undefined,
						}
					: queue,
			);

		const queuesMap = keyBy(queues, 'id');
		refreshEnabledQueues?.forEach((queue) => {
			queuesMap[queue.id] = queue;
		});
		return setQueuesForCategory(data, category, sortBy(Object.values(queuesMap), 'order'));
	});
};

export const refreshIssueCountForQueues = async (
	update: Updater,
	{ baseUrl, projectKey, category }: RestParams,
	queueIds: string[],
	analyticsEvent?: UIAnalyticsEvent,
) => {
	try {
		update((data) => {
			const queues = getQueuesForCategory(data, category)?.filter((queue: Page) =>
				queueIds.includes(queue.id.toString()),
			);

			if (queues && queues.length > 0) {
				updateIssueCountForQueues(
					baseUrl,
					projectKey,
					category,
					queues,
					false,
					update,
					noop,
					analyticsEvent,
				);

				return data ? assocIn(data, [category, 'isCountLoading'], true) : null;
			}
			return data;
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalyticsForRefreshCountFailure(error);
		throw error;
	}
};
