import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import {
	LOAD_ISSUES_ACTION_SOURCE_FILTER,
	LOAD_ISSUES_ACTION_SOURCE_FORCED_UPDATE,
} from '../../model';
import { FILTER_QUERY_SUBMITTED, filterQueryChangedAction } from '../../state/actions/filter';
import { loadIssuesAction } from '../../state/actions/issue/index.tsx';
import type { Action } from '../../state/actions/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>) =>
	action$.ofType(FILTER_QUERY_SUBMITTED).switchMap((action) => {
		const source =
			action.payload.filterQuery === null || action.payload.filterQuery === ''
				? LOAD_ISSUES_ACTION_SOURCE_FORCED_UPDATE
				: LOAD_ISSUES_ACTION_SOURCE_FILTER;

		return Observable.of(
			filterQueryChangedAction(action.payload.filterQuery),
			// @ts-expect-error - TS2769 - No overload matches this call.
			loadIssuesAction(0, source),
		);
	});
