import { connect } from '../../../../common/table-redux';
import DynamicColumnComponent from './dynamic/index-dumb.tsx';
import Column from './index-dumb';

export default connect(
	() => ({
		DynamicColumnComponent,
	}),
	{},
)(Column);
