import flow from 'lodash/flow';
import ReduxAnalyticsData from '@atlassian/jira-analytics-web-react/src/components/redux-analytics-data.tsx';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import { connect } from '@atlassian/jira-react-redux';
import { resetSortOrderAction } from '../../../../../state/actions/table';
import { getSortedByColumn, getSortOrder } from '../../../../../state/selectors/sorting';
import ResetSortingButton from './view';

export default flow(
	withFireUiAnalytics({
		onResetSorting: 'resetSorting',
	}),
	ReduxAnalyticsData((state) => ({
		attributes: {
			fieldId: getSortedByColumn(state),
			sortOrder: getSortOrder(state),
		},
	})),
	connect(null, {
		onResetSorting: resetSortOrderAction,
	}),
)(ResetSortingButton);
